import { authExchange } from '@urql/exchange-auth'
import { cacheExchange, createClient, dedupExchange, fetchExchange, makeOperation, mapExchange, subscriptionExchange } from 'urql'
// import { yogaExchange } from '@graphql-yoga/urql-exchange'

const getAuth = async ({ authState }) => {
    if (!authState) {
        const token = localStorage.getItem('token')

        if (token) return { token }
    }

    return null
}

const addAuthToOperation = ({ authState, operation }) => {
    if (!authState || !authState.token) {
        return operation
    }

    const fetchOptions =
        typeof operation.context.fetchOptions === 'function'
        ? operation.context.fetchOptions()
        : operation.context.fetchOptions || {}

    return makeOperation(operation.kind, operation, {
        ...operation.context,
        fetchOptions: {
            ...fetchOptions,
            headers: {
                ...fetchOptions.headers,
                Authorization: `Bearer ${authState.token}`,
            },
        },
    })
}

class Urql {
    
    constructor () {
        this.client = null
    }

    init = (onError) => {
        this.client = createClient({
            url: process.env.NODE_ENV !== 'development' ? process.env.REACT_APP_GRAPHQL : 'http://192.168.3.58:3050/graphql',
            exchanges: [
                dedupExchange,
                cacheExchange,
                mapExchange({
                    onError,
                }),
                authExchange({
                    getAuth,
                    addAuthToOperation,
                }),
                fetchExchange,
                subscriptionExchange({
                    forwardSubscription(operation) {
                      const url = new URL(process.env.NODE_ENV !== 'development' ? process.env.REACT_APP_GRAPHQL : 'http://192.168.3.58:3050/graphql',)
                      url.searchParams.append('query', operation.query)
                      if (operation.variables) {
                        url.searchParams.append(
                          'variables',
                          JSON.stringify(operation.variables)
                        )
                      }
                      return {
                        subscribe(sink) {
                          const eventsource = new EventSource(url.toString(), {
                            withCredentials: true // This is required for cookies
                          })
                          eventsource.onmessage = (event) => {
                            const data = JSON.parse(event.data)
                            sink.next(data)
                            if (eventsource.readyState === 2) {
                              sink.complete()
                            }
                          }
                          eventsource.onerror = (error) => {
                            sink.error(error)
                          }
                          return {
                            unsubscribe: () => eventsource.close()
                          }
                        }
                      }
                    }
                }),
            ],
        })

        return this.client
    }
}

const urql = new Urql()

export default urql