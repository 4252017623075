import React, { useEffect, useState } from 'react'
import './style.scss'
import { useTranslation } from 'react-i18next'
import { useSubscription } from 'urql'
import ScreenHeader from '../../components/ScreenHeader'
import { GetOrders } from '../../graphql/queries'
import { OrderAdded, OrderRemoved, OrderUpdated } from '../../graphql/subscriptions'
import FilterTable from '../../components/FilterTable'
import OrderLabResultModal from '../../components/OrderLabResultModal'
import { formatDate } from '../../util/format'

const includeFields = [
    {
        value: 'client',
        label: 'Klient',
    },
    {
        value: 'amount',
        label: 'Kogus',
    },
    {
        value: 'comment',
        label: 'Kommentaar',
    },
    {
        value: 'location',
        label: 'Asukoht',
    },
    {
        value: 'pickupDate',
        label: 'Laadimise kuupäev',
    },
    {
        value: 'status',
        label: 'Staatus',
    },
]

const OrdersScreen = () => {

    const { t } = useTranslation()
    const [subActive, setSubActive] = useState(false)
    const [addedResult, setAddedResult] = useState(null)
    const [selectedRow, setSelectedRow] = useState(null)
    const [updatedResult, setUpdatedResult] = useState(null)
    const [removedResult, setRemovedResult] = useState(null)

    useEffect(() => {
        setSubActive(true)

        return () => {
            setSubActive(false)
            setAddedResult(null)
            setUpdatedResult(null)
            setRemovedResult(null)
        }
    }, [])

    useSubscription({
            pause: !subActive,
            query: OrderRemoved,
        },
        (prev, updatedData) => {
            console.log('result removed', updatedData)
            setRemovedResult(updatedData.orderRemoved)
        },
    )

    useSubscription({
            pause: !subActive,
            query: OrderAdded,
        },
        (prev, updatedData) => {
            console.log('result added', updatedData)
            setAddedResult(updatedData.orderAdded)
        },
    )

    useSubscription({
            pause: !subActive,
            query: OrderUpdated,
        },
        (prev, updatedData) => {
            console.log('result added', updatedData)
            setUpdatedResult(updatedData.orderUpdated)
        },
    )

    const fieldConditions = (row, field) => {
        switch (field) {
            case 'amount':
                return `${row?.sentProducts?.length ? row.sentProducts.length : 0} / ${row.amount}`
            case 'client':
                return row[field]?.name || '-'
            case 'warehouse':
                return row.spot?.warehouse?.name || '-'
            case 'createdAt':
            case 'pickupDate':
                return formatDate(row[field])
            case 'status': {
                if (row?.sentProducts?.length || row.completed) return row.sentProducts.length < row.amount && !row.completed ? 
                    <span className='status incomplete'>{ t('orders.incomplete') }</span>
                    :  <span className='status complete'>{ t('orders.complete') }</span>
                return  <span className='status pending'>{ t('orders.pending') }</span>
            }
            case 'location':
                return `${row.labResult?.spot?.warehouse?.name || '-'} / ${row.labResult?.spot?.name || '-'}`
            default:
                return row[field]
        }
    }

    const handleRowClick = (row) => {
        console.log(row)
        setSelectedRow(row)
    }

    return (
        <div className='orders'>
            <ScreenHeader
                title={ t('orders.title') }
            />
            <FilterTable
                query={GetOrders}
                queryKey='getOrders'
                includeFields={includeFields}
                fieldConditions={fieldConditions}
                initialOrderBy='id'
                onRowClick={handleRowClick}
                hideInputs={true}
                subscriptions={{
                    add: addedResult,
                    update: updatedResult,
                    remove: removedResult,
                }}
            />
            <OrderLabResultModal
                show={selectedRow !== null}
                data={selectedRow?.labResult}
                close={() => setSelectedRow(null)}
                title={`${`${selectedRow?.client?.name} ` || ''}${t('lab.title')}`}
            />
        </div>
    )
}

export default OrdersScreen