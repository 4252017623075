import React, { useState } from 'react'
import PropTypes from 'prop-types'

const Textarea = ({
    label, placeholder, value, onChange, error, focus, onKeyUp, maxLength, onFocus, onBlur, disabled
}) => {

  const [isFocused, setIsFocused] = useState(false)

  const handleFocus = () => {
    setIsFocused(true)
    if (onFocus) onFocus()
  }
  const handleBlur = () => {
    setIsFocused(false)
    if (onBlur) onBlur()
  }

  return (
    <div className={`input-wrapper input-wrapper--textarea${error ? ' input-wrapper--error' : ''}`}>
      <label>
        { label && <span>{ label }</span> }
        <div className={`input-wrapper--control${isFocused ? ' input-wrapper--control-focused' : ''}`}>
          <div className='input-wrapper--input'>
            <textarea
              autoFocus={focus}
              placeholder={placeholder}
              onChange={onChange}
              value={value || ''}
              onKeyUp={onKeyUp}
              maxLength={maxLength}
              disabled={disabled}
              onFocus={handleFocus}
              onBlur={handleBlur}
            ></textarea>
          </div>
        </div>
      </label>
      { error &&
        <div className="input-error">{ error }</div>
      }
    </div>
  )
}

Textarea.propTypes = {
    label: PropTypes.string,
    placeholder: PropTypes.string,
    value: PropTypes.string,
    onChange: PropTypes.func,
    error: PropTypes.string,
    maxLength: PropTypes.number,
    focus: PropTypes.bool,
    onKeyUp: PropTypes.func,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    disabled: PropTypes.bool
}

export default Textarea