import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useMutation } from 'urql'
import Button from './Button'
import Input from './Input'
import Modal from './Modal'
import { Responses } from '../util/consts'
import { formatDate } from '../util/format'
import { RemoveLabResult, UpdateLabResult } from '../graphql/mutations'
import BoxNotification from './BoxNotification'
import Textarea from './Textarea'

const initialState = {
    warehouse: '',
    spot: '',
    minProtein: '',
    maxProtein: '',
    minMoisture: '',
    maxMoisture: '',
    minSodium: '',
    maxSodium: '',
    minFat: '',
    maxFat: '',
    minTvbn: '',
    maxTvbn: '',
    antioxidant: '',
}

const initialErrorsState = {
    warehouse: null,
    spot: null,
    minProtein: null,
    maxProtein: null,
    minMoisture: null,
    maxMoisture: null,
    minSodium: null,
    maxSodium: null,
    minFat: null,
    maxFat: null,
    minTvbn: null,
    maxTvbn: null,
    antioxidant: null,
    generic: null,
}

const EditLabResultModal = ({
    show,
    close,
    onSuccess,
    onDelete,
    initialData,
}) => {

    const { t } = useTranslation()
    const [item, setItem] = useState(initialData || initialState)
    const [errors, setErrors] = useState(initialErrorsState)
    const [, updateResult] = useMutation(UpdateLabResult)
    const [, removeResult] = useMutation(RemoveLabResult)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        if (initialData) setItem(initialData)
        return () => setItem(initialState)
    }, [initialData])
    

    const hasInputErrors = () => {
        let hasErrors = false
        const errorState = {
            ...initialErrorsState,
        }

        Object.entries(item).forEach(([key, value]) => {
            if (key === 'productSpots') return
            if (!value) {
                hasErrors = true
                errorState[key] = t('lab.fill_field')
            }
        })

        setErrors({
            ...errorState,
        })
        return hasErrors
    }

    const handleSave = async () => {
        if (hasInputErrors()) return
        setLoading(true)

        try {
            const variables = {}

            Object.entries(item).forEach(([key, value]) => {
                switch (key) {
                    case '__typename':
                    case 'warehouse':
                    case 'spot':
                    case 'createdAt':
                    case 'updatedAt':
                    case 'productSpots':
                    case 'creator':
                    case 'loadStart':
                    case 'loadEnd':
                    case 'id':
                        break
                    case 'comment':
                        variables.comment = value
                        break
                    default:
                        variables[key] = parseFloat(value)
                        break
                }
            })

            const { data } = await updateResult({
                id: item.id,
                data: variables,
            })

            if (!data?.updateLabResult || data.updateLabResult !== Responses.Success) {
                setErrors({
                    ...initialErrorsState,
                    generic: t('lab.update_error'),
                })
                return
            }

            if (onSuccess) onSuccess(data.updateLabResult)
        } catch (err) {
            setErrors({
                ...initialErrorsState,
                generic: t('lab.update_error'),
            })
            console.log(err)
        } finally {
            setLoading(false)
        }
    }

    const handleRemove = async () => {
        setLoading(true)
        try {
            const { data } = await removeResult({
                id: item.id,
            })

            if (!data?.removeLabResult || data.removeLabResult !== Responses.Success) {
                setErrors({
                    ...initialErrorsState,
                    generic: t('lab.remove_error'),
                })
                return
            }

            if (onDelete) onDelete(data.removeLabResult)
        } catch (err) {
            setErrors({
                ...initialErrorsState,
                generic: t('lab.remove_error'),
            })
            console.log(err)
        } finally {
            setLoading(false)
        }
    }

    const handleClose = () => {
        if (close) close()
        setErrors(initialErrorsState)
        setItem(initialState)
    }

    const setField = (field, value) => {
        if (field === 'warehouse') {
            setErrors({
                ...errors,
                [field]: null,
                spot: null,
            })
            setItem({
                ...item,
                [field]: value,
                spot: null,
            })
            return
        }
        setErrors({
            ...errors,
            [field]: null,
        })
        setItem({
            ...item,
            [field]: value,
        })
    }

    return (
        <Modal
            className={'modal-lab'}
            show={show}
            close={handleClose}
            title={ t('lab.edit_title') }
        >
            <div className='modal-lab--warehouse'>{ `${item.spot?.warehouse?.name} / ${item.spot?.name} - ${formatDate(item.createdAt)}` }</div>
            <div className='input-row'>
                <Input
                    label={ t('lab.protein_min') }
                    placeholder={ t('lab.protein_min') }
                    value={item.minProtein}
                    onChange={(e) => setField('minProtein', e.target.value)}
                    error={errors.minProtein}
                    type={'number'}
                />
                <Input
                    label={ t('lab.protein_max') }
                    placeholder={ t('lab.protein_max') }
                    value={item.maxProtein}
                    onChange={(e) => setField('maxProtein', e.target.value)}
                    error={errors.maxProtein}
                    type={'number'}
                />
            </div>

            <div className='input-row'>
                <Input
                    label={ t('lab.moisture_min') }
                    placeholder={ t('lab.moisture_min') }
                    value={item.minMoisture}
                    onChange={(e) => setField('minMoisture', e.target.value)}
                    error={errors.minMoisture}
                    type={'number'}
                />
                <Input
                    label={ t('lab.moisture_max') }
                    placeholder={ t('lab.moisture_max') }
                    value={item.maxMoisture}
                    onChange={(e) => setField('maxMoisture', e.target.value)}
                    error={errors.maxMoisture}
                    type={'number'}
                />
            </div>

            <div className='input-row'>
                <Input
                    label={ t('lab.fat_min') }
                    placeholder={ t('lab.fat_min') }
                    value={item.minFat}
                    onChange={(e) => setField('minFat', e.target.value)}
                    error={errors.minFat}
                    type={'number'}
                />
                <Input
                    label={ t('lab.fat_max') }
                    placeholder={ t('lab.fat_max') }
                    value={item.maxFat}
                    onChange={(e) => setField('maxFat', e.target.value)}
                    error={errors.maxFat}
                    type={'number'}
                />
            </div>

            <div className='input-row'>
                <Input
                    label={ t('lab.sodium_min') }
                    placeholder={ t('lab.sodium_min') }
                    value={item.minSodium}
                    onChange={(e) => setField('minSodium', e.target.value)}
                    error={errors.minSodium}
                    type={'number'}
                />
                <Input
                    label={ t('lab.sodium_max') }
                    placeholder={ t('lab.sodium_max') }
                    value={item.maxSodium}
                    onChange={(e) => setField('maxSodium', e.target.value)}
                    error={errors.maxSodium}
                    type={'number'}
                />
            </div>

            <div className='input-row'>
                <Input
                    label={ t('lab.tvbn_min') }
                    placeholder={ t('lab.tvbn_min') }
                    value={item.minTvbn}
                    onChange={(e) => setField('minTvbn', e.target.value)}
                    error={errors.minTvbn}
                    type={'number'}
                />
                <Input
                    label={ t('lab.tvbn_max') }
                    placeholder={ t('lab.tvbn_max') }
                    value={item.maxTvbn}
                    onChange={(e) => setField('maxTvbn', e.target.value)}
                    error={errors.maxTvbn}
                    type={'number'}
                />
            </div>

            <Textarea
                label={t('lab.comment')}
                placeholder={t('lab.comment')}
                value={item.comment}
                onChange={(e) => setField('comment', e.target.value)}
            />

            {
                errors?.generic ?
                <BoxNotification
                    message={errors.generic}
                    type={'error'}
                />
                :
                <></>
            }

            <div className='actions'>
                <Button
                    className={'btn-cancel'}
                    label={ t('lab.remove') }
                    onClick={handleRemove}
                    loading={loading}
                />
                <Button
                    label={ t('lab.save') }
                    onClick={handleSave}
                    loading={loading}
                />
            </div>
        </Modal>
    )
}

EditLabResultModal.propTypes = {
    show: PropTypes.bool,
    close: PropTypes.func,
    onSuccess: PropTypes.func,
    onDelete: PropTypes.func,
    initialData: PropTypes.object,
}

export default EditLabResultModal