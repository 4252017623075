import React from 'react'
import './style.scss'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'urql'
import { GetStatistics } from '../../graphql/queries'
import { getTonnes } from '../../util/helpers'



const StatisticsScreen = () => {

    const { t } = useTranslation()
    const [statsQuery] = useQuery({
        query: GetStatistics,
        requestPolicy: 'network-only',
    })

    if (!statsQuery?.data?.getStatistics) return <></>

    return (
        <div className='statistics'>
            <h3>{ t('stats.title') }</h3>
            <div className='statistics-content'>
                <div className='statistics-content-item'>
                    <span>{ t('stats.yearly') }</span>
                    <span>{ `${getTonnes(statsQuery?.data?.getStatistics?.year)} t` }</span>
                </div>
                <div className='statistics-content-item'>
                    <span>{ t('stats.monthly') }</span>
                    <span>{ `${getTonnes(statsQuery?.data?.getStatistics?.month)} t` }</span>
                </div>
                <div className='statistics-content-item'>
                    <span>{ t('stats.current') }</span>
                    <span>{ `${getTonnes(statsQuery?.data?.getStatistics?.current)} t` }</span>
                </div>
            </div>
        </div>
    )
}

export default StatisticsScreen