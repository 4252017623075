import React from 'react'
import PropTypes from 'prop-types'
import CheckmarkIcon from './icons/checkmark'

const Checkbox = ({
  label,
  value,
  onChange, 
  error, 
  disabled, 
  onBlur, 
  className,
}) => (
  <div className={`input-wrapper input-wrapper--checkbox${error ? ' input-wrapper--error' : ''}${value ? ' input-wrapper--checkbox-checked' : ''}${disabled ? ' input-wrapper--checkbox-disabled' : ''}${className ? ` ${className}` : ''}`}>
    <label>
      <input type={'checkbox'} checked={value} onChange={onChange} disabled={disabled} onBlur={onBlur} />
      <div className='input-wrapper--checkbox-box'>
        <CheckmarkIcon />
      </div>
      { label && <span>{ label }</span> }
    </label>
    { error &&
      <div className="input-error">{ error }</div>
    }
  </div>
)

Checkbox.propTypes = {
  label: PropTypes.string,
  value: PropTypes.bool,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  className: PropTypes.string,
  error: PropTypes.string,
  disabled: PropTypes.bool,
}

export default Checkbox