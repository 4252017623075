import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useMutation } from 'urql'
import Button from './Button'
import Input from './Input'
import Modal from './Modal'
import { Responses } from '../util/consts'
import { UpdateProduct } from '../graphql/mutations'
import BoxNotification from './BoxNotification'

const initialState = {
    weight: '',
}

const initialErrorsState = {
    weight: null,
}

const EditProductModal = ({
    show,
    close,
    onSuccess,
    initialData,
}) => {

    const { t } = useTranslation()
    const [item, setItem] = useState(initialData || initialState)
    const [errors, setErrors] = useState(initialErrorsState)
    const [, updateProduct] = useMutation(UpdateProduct)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        if (initialData) setItem(initialData)
        return () => setItem(initialState)
    }, [initialData])
    

    const hasInputErrors = () => {
        let hasErrors = false
        const errorState = {
            ...initialErrorsState,
        }

        if (!item.weight) {
            hasErrors = true
            errorState.weight = t('lab.fill_field')
        }

        setErrors({
            ...errorState,
        })
        return hasErrors
    }

    const handleSave = async () => {
        if (hasInputErrors()) return
        setLoading(true)

        try {
            const variables = {
                weight: parseFloat(item.weight),
            }

            const { data } = await updateProduct({
                id: item.id,
                data: variables,
            }, {
                additionalTypenames: ['Products'],
            })

            if (!data?.updateProduct || data.updateProduct !== Responses.Success) {
                setErrors({
                    ...initialErrorsState,
                    generic: t('product.update_error'),
                })
                return
            }

            if (onSuccess) onSuccess()
        } catch (err) {
            setErrors({
                ...initialErrorsState,
                generic: t('product.update_error'),
            })
            console.log(err)
        } finally {
            setLoading(false)
        }
    }

    const handleClose = () => {
        if (close) close()
        setErrors(initialErrorsState)
        setItem(initialState)
    }

    const setField = (field, value) => {
        if (field === 'warehouse') {
            setErrors({
                ...errors,
                [field]: null,
                spot: null,
            })
            setItem({
                ...item,
                [field]: value,
                spot: null,
            })
            return
        }
        setErrors({
            ...errors,
            [field]: null,
        })
        setItem({
            ...item,
            [field]: value,
        })
    }

    return (
        <Modal
            className={'modal-lab'}
            show={show}
            close={handleClose}
            title={ t('product.title') }
        >
            <div className='modal-lab--warehouse'>{ `${item.code}` }</div>
            <div className='input-row'>
                <Input
                    label={ t('product.weight') }
                    placeholder={ t('product.weight') }
                    value={item.weight}
                    onChange={(e) => setField('weight', e.target.value)}
                    error={errors.weight}
                    type={'number'}
                    suffix={'kg'}
                />
            </div>

            {
                errors?.generic ?
                <BoxNotification
                    message={errors.generic}
                    type={'error'}
                />
                :
                <></>
            }

            <div className='actions'>
                <Button
                    label={ t('product.save') }
                    onClick={handleSave}
                    loading={loading}
                />
            </div>
        </Modal>
    )
}

EditProductModal.propTypes = {
    show: PropTypes.bool,
    close: PropTypes.func,
    onSuccess: PropTypes.func,
    onDelete: PropTypes.func,
    initialData: PropTypes.object,
}

export default EditProductModal