import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useMutation, useQuery } from 'urql'
import Button from './Button'
import Input from './Input'
import Modal from './Modal'
import { Responses } from '../util/consts'
import { CreateLabResult } from '../graphql/mutations'
import { GetWarehouses } from '../graphql/queries'
import BoxNotification from './BoxNotification'
import SelectInput from './SelectInput'
import Textarea from './Textarea'

const initialState = {
    warehouse: '',
    spot: '',
    minProtein: '',
    maxProtein: '',
    minMoisture: '',
    maxMoisture: '',
    minSodium: '',
    maxSodium: '',
    minFat: '',
    maxFat: '',
    minTvbn: '',
    maxTvbn: '',
    comment: '',
}

const initialErrorsState = {
    warehouse: null,
    spot: null,
    minProtein: null,
    maxProtein: null,
    minMoisture: null,
    maxMoisture: null,
    minSodium: null,
    maxSodium: null,
    minFat: null,
    maxFat: null,
    minTvbn: null,
    maxTvbn: null,
    generic: null,
}

const AddLabResultModal = ({
    show,
    close,
    onSuccess,
    selectedSpot,
}) => {
    const { t } = useTranslation()
    const [item, setItem] = useState(initialState)
    const [errors, setErrors] = useState(initialErrorsState)
    const [, addResult] = useMutation(CreateLabResult)
    const [loading, setLoading] = useState(false)
    const [warehouses] = useQuery({
        pause: selectedSpot?.id,
        query: GetWarehouses,
        variables: {
            page: 0,
            limit: 100,
            sort: 'asc',
            orderBy: 'name',
        },
    })

    const hasInputErrors = () => {
        let hasErrors = false
        const errorState = {
            ...initialErrorsState,
        }

        Object.entries(item).forEach(([key, value]) => {
            if (key === 'warehouse' || (selectedSpot?.id && key === 'spot')) return

            if (!value) {
                hasErrors = true
                errorState[key] = t('lab.fill_field')
            }
        })

        console.log('hasErrors', errorState)

        setErrors({
            ...errorState,
        })
        return hasErrors
    }

    const handleClose = () => {
        if (close) close()
        setErrors(initialErrorsState)
        setItem(initialState)
    }

    const handleAdd = async () => {
        if (hasInputErrors()) return
        setLoading(true)
        try {
            const variables = {}

            Object.entries(item).forEach(([key, value]) => {
                switch (key) {
                    case 'warehouse':
                        break
                    case 'comment':
                        variables.comment = value
                        break
                    case 'spot':
                        variables.spotId = selectedSpot?.id ? parseInt(selectedSpot.id, 10) : parseInt(value.id, 10)
                        break
                    default:
                        variables[key] = parseFloat(value)
                        break
                }
            })

            const { data } = await addResult({
                data: variables,
            })

            if (!data?.createLabResult || data.createLabResult !== Responses.Success) {
                setErrors({
                    ...initialErrorsState,
                    generic: t('lab.add_error'),
                })
                return
            }

            if (onSuccess) {
                onSuccess(data.createLabResult)
                handleClose()
            }
        } catch (err) {
            setErrors({
                ...initialErrorsState,
                generic: t('lab.add_error'),
            })
            console.log(err)
        } finally {
            setLoading(false)
        }
    }

    const setField = (field, value) => {
        if (field === 'warehouse') {
            setErrors({
                ...errors,
                [field]: null,
                spot: null,
            })
            setItem({
                ...item,
                [field]: value,
                spot: null,
            })
            return
        }
        setErrors({
            ...errors,
            [field]: null,
        })
        setItem({
            ...item,
            [field]: value,
        })
    }

    return (
        <Modal
            className={'modal-lab'}
            show={show}
            close={handleClose}
            title={t('lab.add_title')}
        >

            {
                !selectedSpot?.id ?
                    <div className='input-row warehouses'>
                        <SelectInput
                            label={t('lab.warehouses')}
                            options={warehouses.data?.getWarehouses}
                            value={warehouses.data?.getWarehouses?.find(x => x.id === item.warehouse?.id)}
                            getOptionLabel={(option) => t(option.name)}
                            getOptionValue={(option) => option.id}
                            onChange={(option) => setField('warehouse', option)}
                            error={errors.warehouse}
                        />

                        {
                            item.warehouse ?
                                <SelectInput
                                    label={t('lab.warehouse_spot')}
                                    options={item.warehouse.spots}
                                    value={item.spot ? item.warehouse.spots.find(x => x.id === item.spot.id) : null}
                                    getOptionLabel={(option) => t(option.name)}
                                    getOptionValue={(option) => option.id}
                                    onChange={(option) => setField('spot', option)}
                                    error={errors.spot}
                                />
                                :
                                <></>
                        }
                    </div>
                    :
                    <div className='input-row warehouses'>
                        <h4>{selectedSpot.name}</h4>
                    </div>
            }

            <div className='input-row'>
                <Input
                    label={t('lab.protein_min')}
                    placeholder={t('lab.protein_min')}
                    value={item.minProtein}
                    onChange={(e) => setField('minProtein', e.target.value)}
                    error={errors.minProtein}
                    type={'number'}
                />
                <Input
                    label={t('lab.protein_max')}
                    placeholder={t('lab.protein_max')}
                    value={item.maxProtein}
                    onChange={(e) => setField('maxProtein', e.target.value)}
                    error={errors.maxProtein}
                    type={'number'}
                />
            </div>

            <div className='input-row'>
                <Input
                    label={t('lab.moisture_min')}
                    placeholder={t('lab.moisture_min')}
                    value={item.minMoisture}
                    onChange={(e) => setField('minMoisture', e.target.value)}
                    error={errors.minMoisture}
                    type={'number'}
                />
                <Input
                    label={t('lab.moisture_max')}
                    placeholder={t('lab.moisture_max')}
                    value={item.maxMoisture}
                    onChange={(e) => setField('maxMoisture', e.target.value)}
                    error={errors.maxMoisture}
                    type={'number'}
                />
            </div>

            <div className='input-row'>
                <Input
                    label={t('lab.fat_min')}
                    placeholder={t('lab.fat_min')}
                    value={item.minFat}
                    onChange={(e) => setField('minFat', e.target.value)}
                    error={errors.minFat}
                    type={'number'}
                />
                <Input
                    label={t('lab.fat_max')}
                    placeholder={t('lab.fat_max')}
                    value={item.maxFat}
                    onChange={(e) => setField('maxFat', e.target.value)}
                    error={errors.maxFat}
                    type={'number'}
                />
            </div>

            <div className='input-row'>
                <Input
                    label={t('lab.sodium_min')}
                    placeholder={t('lab.sodium_min')}
                    value={item.minSodium}
                    onChange={(e) => setField('minSodium', e.target.value)}
                    error={errors.minSodium}
                    type={'number'}
                />
                <Input
                    label={t('lab.sodium_max')}
                    placeholder={t('lab.sodium_max')}
                    value={item.maxSodium}
                    onChange={(e) => setField('maxSodium', e.target.value)}
                    error={errors.maxSodium}
                    type={'number'}
                />
            </div>

            <div className='input-row'>
                <Input
                    label={t('lab.tvbn_min')}
                    placeholder={t('lab.tvbn_min')}
                    value={item.minTvbn}
                    onChange={(e) => setField('minTvbn', e.target.value)}
                    error={errors.minTvbn}
                    type={'number'}
                />
                <Input
                    label={t('lab.tvbn_max')}
                    placeholder={t('lab.tvbn_max')}
                    value={item.maxTvbn}
                    onChange={(e) => setField('maxTvbn', e.target.value)}
                    error={errors.maxTvbn}
                    type={'number'}
                />
            </div>

            <Textarea
                label={t('lab.comment')}
                placeholder={t('lab.comment')}
                value={item.comment}
                onChange={(e) => setField('comment', e.target.value)}
            />

            {
                errors?.generic ?
                    <BoxNotification
                        message={errors.generic}
                        type={'error'}
                    />
                    :
                    <></>
            }

            <Button
                label={t('lab.save')}
                onClick={handleAdd}
                loading={loading}
            />
        </Modal>
    )
}

AddLabResultModal.propTypes = {
    show: PropTypes.bool,
    close: PropTypes.func,
    onSuccess: PropTypes.func,
    selectedSpot: PropTypes.object,
}

export default AddLabResultModal