/* eslint-disable no-unused-vars */
import React, { useEffect, useMemo, useRef, useState } from 'react'
import './style.scss'
import { useTranslation } from 'react-i18next'
import { useSubscription } from 'urql'
import FilterTable from '../../components/FilterTable'
import ScreenHeader from '../../components/ScreenHeader'
import { GetProducts } from '../../graphql/queries'
import { formatDate } from '../../util/format'
import { ProductAdded } from '../../graphql/subscriptions'
import ProductFilters from '../../components/ProductFilters'
import { ProductStatus } from '../../util/consts'
import EditProductModal from '../../components/EditProductModal'

const includeFields = [
    {
        value: 'warehouse',
        label: 'Ladu',
    },
    {
        value: 'productSpot',
        label: 'Laokoht',
    },
    {
        value: 'code',
        label: 'Kood',
    },
    {
        value: 'weight',
        label: 'Kaal',
    },
    {
        value: 'createdAt',
        label: 'Kuupäev',
    },
    {
        value: 'creator',
        label: 'Lisaja',
    },
    {
        value: 'client',
        label: 'Klient',
    },
    {
        value: 'sent',
        label: 'Väljastatud',
    },
    {
        value: 'removed',
        label: 'Eemaldatud',
    },
]

const ProductsScreen = () => {
    const { t } = useTranslation()
    const tableRef = useRef()
    const [addedResult, setAddedResult] = useState(null)
    const [subActive, setSubActive] = useState(false)
    const [extraFilters, setExtraFilters] = useState()
    const [showEditModal, setShowEditModal] = useState(false)
    const [selectedRow, setSelectedRow] = useState(null)

    const context = useMemo(() => ({ additionalTypenames: ['Products'] }), [])

    useEffect(() => {
        setSubActive(true)

        return () => {
            setSubActive(false)
            setAddedResult(null)
        }
    }, [])
    
    useSubscription({
            pause: !subActive,
            query: ProductAdded,
        },
        (prev, updatedData) => {
            setAddedResult(updatedData.productAdded)
        },
    )

    const handleFilterChange = (filters) => {
        setExtraFilters(filters)
    }

    useEffect(() => {
        handleFilterChange()
    }, [])

    const fieldConditions = (row, field) => {
        switch (field) {
            case 'creator':
                return row[field]?.name || ''
            case 'weight':
                return `${row[field]} kg`
            case 'productSpot':
                if (row.clientProduct) return row.clientProduct.spot.name
                return row[field]?.spot?.name || '-'
            case 'warehouse':
                if (row.clientProduct) return row.clientProduct.spot.warehouse.name
                return row.productSpot?.spot?.warehouse?.name || '-'
            case 'createdAt':
                return formatDate(row[field])
            case 'removed':
                return row?.status?.label === ProductStatus.Removed ? formatDate(row.updatedAt) : ''
            case 'client': {
                if (row.clientProduct?.client?.name) return row.clientProduct.client.name
                if (row.productSpot?.labResult?.bookedProducts?.length) {
                    return row.productSpot.labResult.bookedProducts.map(x => x.client.name).join(', ')
                }
                return '-'
            }
            case 'sent':
                return row.clientProduct?.createdAt ? formatDate(row.clientProduct.createdAt) : '-'
            default:
                return row[field]
        }
    }

    const handleCloseEdit = () => {
        setShowEditModal(false)
        setSelectedRow(null)
    }

    const handleEdited = () => {
        handleCloseEdit()
    }

    const handleRowClick = (row) => {
        setSelectedRow(row)
        setShowEditModal(true)
    }

    return (
        <div className='products'>
            <ScreenHeader
                title={ t('products.title') }
            />
            <ProductFilters
                onFilterChange={handleFilterChange}
            />
            <FilterTable
                ref={tableRef}
                query={GetProducts}
                queryKey='getProducts'
                includeFields={includeFields}
                fieldConditions={fieldConditions}
                initialOrderBy='id'
                extraFilters={extraFilters}
                useExtraFilters={true}
                hideInputs={true}
                onRowClick={handleRowClick}
                queryContext={context}
                subscriptions={{
                    add: addedResult,
                }}
                statusColorEnabled
                rowBackgroundColor={(row) => row?.status?.label === 'REMOVED' ? '#eef0f6' : undefined}
            />
            <EditProductModal
                show={showEditModal}
                initialData={selectedRow}
                onSuccess={handleEdited}
                close={handleCloseEdit}
            />
        </div>
    )
}

export default ProductsScreen