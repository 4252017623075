const formatDate = (input, time = false) => {
    if (!input) return ''
    if (time) {
        return (new Date(input)).toLocaleString('et-EE', {
            month: '2-digit',
            day: '2-digit',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
        })
    }
    return (new Date(input)).toLocaleString('et-EE', {
        month: '2-digit',
        day: '2-digit',
        year: 'numeric',
    })
}

const parseDate = (input) => {
    if (!input) return null
    return input.toISOString()
}

const formatPrice = (input) => input.toLocaleString('et', {
    maximumFractionDigits: 2,
})

export {
    formatDate,
    parseDate,
    formatPrice,
}