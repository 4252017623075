import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { getTonnes, maxProducts } from '../util/helpers'

const getTotalWeight = (spots) => {
    const weight = spots.reduce((acc, spot) => {
        if (spot.products?.length) {
            return acc + spot.products.reduce((prodAcc, curr) => prodAcc + (curr.product?.weight ? curr.product.weight : 0), 0)
        }
        return acc
    }, 0)
    return `${getTonnes(weight)} t`
}

const getTotalSpots = (spots) => {
    const total = spots.reduce((acc, spot) => acc + (spot.maxProducts ? spot.maxProducts : maxProducts), 0)
    const occupied = spots.reduce((acc, spot) => acc + (spot.products?.length ? spot.products.length : 0), 0)

    return `${occupied} / ${total}`
}

const getLabResult = (spots) => `${spots.reduce((acc, spot) => acc + (spot.currentLabResult ? 1 : 0), 0)} / ${spots.length}`

const Stats = ({
    warehouse,
}) => {
    const { t } = useTranslation()

    if (!warehouse) return <></>
    
    return (
        <div className="stats">
            <div className='stats-row'>
                <span>{ t('stats.spots') }</span>
                <span>{ getTotalSpots(warehouse.spots) }</span>
            </div>
            <div className='stats-row'>
                <span>{ t('stats.weight') }</span>
                <span>{ getTotalWeight(warehouse.spots) }</span>
            </div>
            <div className='stats-row'>
                <span>{ t('stats.lab_results') }</span>
                <span>{ getLabResult(warehouse.spots) }</span>
            </div>
        </div>
    )
}

Stats.propTypes = {
    warehouse: PropTypes.object.isRequired,
}

export default Stats