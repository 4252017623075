import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import Input from './Input'
import SelectInput from './SelectInput'
import { UserRoles } from '../util/consts'
import SearchIcon from './icons/search'
import FiltersIcon from './icons/filters'
import Button from './Button'

const initialState = {
    query: '',
    role: '',
}

const roles = [
    {
        value: null,
        label: 'users.all',
    },
    {
        value: UserRoles.Admin,
        label: 'roles.ADMIN',
    },
    {
        value: UserRoles.Owner,
        label: 'roles.OWNER',
    },
    {
        value: UserRoles.Lab,
        label: 'roles.LAB',
    },
    {
        value: UserRoles.Sales,
        label: 'roles.SALES',
    },
    {
        value: UserRoles.Warehouse,
        label: 'roles.WAREHOUSE',
    },
]

const UserFilters = ({
    onFilterChange,
}) => {

    const { t } = useTranslation()
    const [filters, setFilters] = useState(initialState)
    const [filtersOpened, setFiltersOpened] = useState(false)

    useEffect(() => {
        if (onFilterChange) onFilterChange(filters)
    }, [])

    const setField = (field, value) => {
        const newVal = {
            ...filters,
            [field]: value,
        }

        setFilters(newVal)

        if (onFilterChange) onFilterChange(newVal)
    }

    return (
        <div
            className={`filters-wrapper${filtersOpened ? ' filters--open' : ''}`}
        >
            <Button
                className='btn-filters'
                icon={<FiltersIcon fill={'#fff'} />}
                label={filtersOpened ? t('filters.filter_close') : t('filters.filter')}
                onClick={() => setFiltersOpened(!filtersOpened)}
            />
            <div className='filters filters-user'>
                <div className='filters-search--query'>
                    <Input
                        label={ t('users.search_title') }
                        placeholder={ t('users.search') }
                        onChange={(e) => setField('query', e.target.value)}
                        value={filters.query}
                        icon={<SearchIcon />}
                    />
                </div>
                <div className='filters-search--role'>
                    <SelectInput
                        label={ t('users.search_role') }
                        options={roles}
                        value={roles.find(x => x.value === filters.role)}
                        getOptionLabel={(option) => t(option.label)}
                        onChange={(option) => setField('role', option.value)}
                    />
                </div>
            </div>
        </div>
    )
}

UserFilters.propTypes = {
    onFilterChange: PropTypes.func,
}

export default UserFilters