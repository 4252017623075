import React, { useEffect, useRef, useState } from 'react'
import './style.scss'
import { useTranslation } from 'react-i18next'
import Button from '../../components/Button'
import FilterTable from '../../components/FilterTable'
import ScreenHeader from '../../components/ScreenHeader'
import { GetClients } from '../../graphql/queries'
import ClientFilters from '../../components/ClientFilters'
import AddClientModal from '../../components/AddClientModal'
import EditClientModal from '../../components/EditClientModal'

const includeFields = [
    {
        value: 'name',
        label: 'Nimi',
    },
    {
        value: 'email',
        label: 'E-post',
    },
    {
        value: 'phone',
        label: 'Telefon',
    },
    {
        value: 'address',
        label: 'Aadress',
    },
]

const ClientsScreen = () => {

    const { t } = useTranslation()
    const tableRef = useRef()
    const [extraFilters, setExtraFilters] = useState()
    const [showAddModal, setShowAddModal] = useState(false)
    const [showEditModal, setShowEditModal] = useState(false)
    const [selectedRow, setSelectedRow] = useState()

    const fieldConditions = (row, field) => {
        switch (field) {
            default:
                return row[field] ? row[field] : '-'
        }
    }

    const handleAddSuccess = () => {
        tableRef?.current?.refresh()
        setShowAddModal(false)
    }

    const handleEditSuccess = () => {
        tableRef?.current?.refresh()
        setShowEditModal(false)
    }

    const handleFilterChange = (filters) => {
        setExtraFilters(filters)
    }

    useEffect(() => {
        handleFilterChange()
    }, [])

    const handleRowClick = (row) => {
        setSelectedRow(row)
        setShowEditModal(true)
    }

    const handleCloseEditModal = () => {
        setShowEditModal(false)
        setSelectedRow(null)
    }

    const renderHeaderActions = () => (
        <>
            <Button
                label={t('clients.add_client')}
                onClick={() => setShowAddModal(true)}
            />
        </>
    )

    return (
        <div className='clients'>
            <ScreenHeader
                title={ t('clients.title') }
                actions={renderHeaderActions}
            />
            <ClientFilters
                onFilterChange={handleFilterChange}
            />
            <FilterTable
                ref={tableRef}
                query={GetClients}
                queryKey='getClients'
                includeFields={includeFields}
                fieldConditions={fieldConditions}
                initialOrderBy='name'
                hideInputs={true}
                extraFilters={extraFilters}
                useExtraFilters={true}
                onRowClick={handleRowClick}
            />
            <AddClientModal
                show={showAddModal}
                onSuccess={handleAddSuccess}
                close={() => setShowAddModal(false)}
            />
            <EditClientModal
                show={showEditModal}
                onSuccess={handleEditSuccess}
                close={handleCloseEditModal}
                client={selectedRow}
            />
        </div>
    )
}

export default ClientsScreen