const UserRoles = {
    Admin: 'ADMIN',
    Owner: 'OWNER',
    Warehouse: 'WAREHOUSE',
    Lab: 'LAB',
    Sales: 'SALES',
    Manager: 'MANAGER',
}

const Tags = {}

const Responses = {
    Failed: 'FAIL',
    Success: 'SUCCESS',
    Exists: 'EXISTS',
}

const ReducerActions = {
    Add: 'ADD',
    Update: 'UPDATE',
    Delete: 'DELETE',
    Replace: 'REPLACE',
}

const Actions = {
    Add: 'ADD',
    Update: 'UPDATE',
    Delete: 'DELETE',
}

const ProductStatus = {
    Ready: 'READY',
    Repackaging: 'REPACKAGING',
    Removed: 'REMOVED',
    Sent: 'SENT',
    Stored: 'STORED',
}

const isDev = process.env.NODE_ENV === 'development'

export {
    UserRoles,
    Tags,
    Responses,
    ReducerActions,
    Actions,
    ProductStatus,
    isDev,
}