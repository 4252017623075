import { memo, useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'

let barcode = ''

const ScannerInput = memo(({
    onBarcodeScanned,
}) => {

    const handleKeyPress = useCallback((e) => {
        if (e.key !== 'Enter') {
            barcode += e.key
            return
        }
        console.log('barcode from event:', barcode)
        onBarcodeScanned(barcode)
        barcode = ''
    }, [])

    useEffect(() => {
        console.log('adding scanner event')

        document.addEventListener('keypress', handleKeyPress)

        return () => {
            console.log('removing scanner event')
            barcode = ''
            document.removeEventListener('keypress', handleKeyPress)
        }
    }, [])
})

ScannerInput.displayName = 'ScannerInput'

ScannerInput.propTypes = {
    onBarcodeScanned: PropTypes.func,
}

export default ScannerInput