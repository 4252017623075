import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useQuery } from 'urql'
import { useTranslation } from 'react-i18next'
import Input from './Input'
import SearchIcon from './icons/search'
import FiltersIcon from './icons/filters'
import Button from './Button'
import SelectInput from './SelectInput'
import { GetWarehouses } from '../graphql/queries'

const initialState = {
    query: '',
    warehouse: null,
    spot: null,
}

const ProductFilters = ({
    onFilterChange,
}) => {

    const { t } = useTranslation()
    const [filters, setFilters] = useState(initialState)
    const [filtersOpened, setFiltersOpened] = useState(false)

    const [warehouseQuery] = useQuery({
        query: GetWarehouses,
        variables: {
            page: 0,
            limit: 100,
            orderBy: 'name',
        },
    })

    useEffect(() => {
        if (onFilterChange) onFilterChange(filters)
    }, [])

    const setField = (field, value) => {
        const newVal = {
            ...filters,
            [field]: value,
        }

        setFilters(newVal)

        if (onFilterChange) onFilterChange(newVal)
    }

    return (
        <div
            className={`filters-wrapper${filtersOpened ? ' filters--open' : ''}`}
        >
            <Button
                className='btn-filters'
                icon={<FiltersIcon fill={'#fff'} />}
                label={filtersOpened ? t('filters.filter_close') : t('filters.filter')}
                onClick={() => setFiltersOpened(!filtersOpened)}
            />
            <div className='filters filters-products'>
                <div className='filters-search--query'>
                    <Input
                        label={ t('products.search') }
                        placeholder={ t('products.search') }
                        onChange={(e) => setField('query', e.target.value)}
                        value={filters.query}
                        icon={<SearchIcon />}
                    />
                </div>
                <div className='filters-warehouse'>
                    <SelectInput
                        label={ t('lab.warehouse') }
                        options={warehouseQuery?.data?.getWarehouses}
                        value={warehouseQuery?.data?.getWarehouses?.find(x => x.id === filters.warehouse)}
                        getOptionLabel={(option) => t(option.name)}
                        getOptionValue={(option) => option.id}
                        onChange={(option) => setField('warehouse', option?.id)}
                        isClearable
                    />
                    {
                        filters.warehouse ?
                        <SelectInput
                            label={ t('lab.warehouse_spot') }
                            options={warehouseQuery?.data?.getWarehouses?.find(x => x.id === filters.warehouse).spots}
                            value={filters.spot ? warehouseQuery?.data?.getWarehouses?.find(x => x.id === filters.warehouse).spots.find(x => x.id === filters.spot) : null}
                            getOptionLabel={(option) => t(option.name)}
                            getOptionValue={(option) => option.id}
                            onChange={(option) => setField('spot', option?.id)}
                            isClearable
                        />
                        :
                        <></>
                    }
                </div>
            </div>
        </div>
    )
}

ProductFilters.propTypes = {
    onFilterChange: PropTypes.func,
}

export default ProductFilters