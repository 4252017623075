const Login = `
    mutation login ($email: String!, $password: String!) {
        login (email: $email, password: $password) {
            status
            token
        }
    }
`

const ResetPassword = `
    mutation resetPassword ($email: String!) {
        resetPassword (email: $email)
    }
`

const ChangePassword = `
    mutation changePassword ($password: String!) {
        changePassword (password: $password)
    }
`

const ValidateToken = `
    mutation validateToken ($token: String!) {
        validateToken (token: $token)
    }
`

const CreateUser = `
    mutation createUser ($name: String!, $email: String!, $role: String!) {
        createUser (name: $name, email: $email, role: $role)
    }
`

const UpdateUser = `
    mutation updateUser ($id: Int!, $data: UserInput!) {
        updateUser (id: $id, data: $data)
    }
`

const RemoveUser = `
    mutation removeUser ($id: Int!) {
        removeUser (id: $id)
    }
`

const CreateClient = `
    mutation createClient ($name: String!, $email: String, $address: String!, $phone: String) {
        createClient (name: $name, email: $email, address: $address, phone: $phone)
    }
`

const UpdateClient = `
    mutation updateClient ($id: Int!, $data: ClientInput!) {
        updateClient (id: $id, data: $data)
    }
`

const CreateWarehouse = `
    mutation createWarehouse ($name: String!) {
        createWarehouse (name: $name)
    }
`

const CreateWarehouseSpot = `
    mutation createWarehouseSpot ($warehouse: Int!, $name: String!, $maxProducts: Int) {
        createWarehouseSpot (warehouse: $warehouse, name: $name, maxProducts: $maxProducts)
    }
`

const UpdateWarehouseSpot = `
    mutation updateWarehouseSpot ($id: Int!, $data: WarehouseSpotInput!) {
        updateWarehouseSpot (id: $id, data: $data)
    }
`

const RemoveWarehouseSpot = `
    mutation removeWarehouseSpot ($id: Int!) {
        removeWarehouseSpot (id: $id)
    }
`

const RemoveWarehouse = `
    mutation removeWarehouse ($id: Int!) {
        removeWarehouse (id: $id)
    }
`

const CreateProduct = `
    mutation createProduct ($code: String!, $weight: Float!) {
        createProduct (code: $code, weight: $weight)
    }
`

const UpdateProduct = `
    mutation updateProduct ($id: Int!, $data: ProductInput!) {
        updateProduct (id: $id, data: $data)
    }
`

const RemoveProduct = `
    mutation removeProduct ($id: Int!) {
        removeProduct (id: $id)
    }
`

const SetProductSpot = `
    mutation setProductSpot ($productId: Int!, $spotId: Int!) {
        setProductSpot (productId: $productId, spotId: $spotId)
    }
`

const RemoveProductSpot = `
    mutation removeProductSpot ($productId: Int!) {
        removeProductSpot (productId: $productId)
    }
`

const CreateLabResult = `
    mutation createLabResult ($data: LabResultInput!) {
        createLabResult (data: $data)
    }
`

const UpdateLabResult = `
    mutation updateLabResult ($id: Int!, $data: LabResultInput!) {
        updateLabResult (id: $id, data: $data)
    }
`

const RemoveLabResult = `
    mutation removeLabResult ($id: Int!) {
        removeLabResult (id: $id)
    }
`

const SaveProductsToClient = `
    mutation saveProductsToClient ($clientId: Int!, $labResultId: Int!, $amount: Int!, $pickup: String!, $comment: String) {
        saveProductsToClient (clientId: $clientId, labResultId: $labResultId, amount: $amount, pickup: $pickup, comment: $comment)
    }
`

const RemoveProductsFromClient = `
    mutation removeProductsFromClient ($bookingId: Int!) {
        removeProductsFromClient (bookingId: $bookingId)
    }
`

const IssueProductToClient = `
    mutation issueProductToClient ($productId: Int!, $bookId: Int!) {
        issueProductToClient (productId: $productId, bookId: $bookId)
    }
`

const SwapSpot = `
    mutation swapSpot ($currentId: Int!, $nextId: Int!) {
        swapSpot (currentId: $currentId, nextId: $nextId)
    }
`

export {
    Login,
    ResetPassword,
    ChangePassword,
    ValidateToken,
    CreateUser,
    UpdateUser,
    CreateClient,
    UpdateClient,
    CreateWarehouse,
    CreateWarehouseSpot,
    RemoveWarehouseSpot,
    RemoveWarehouse,
    CreateProduct,
    UpdateProduct,
    RemoveProduct,
    SetProductSpot,
    RemoveProductSpot,
    CreateLabResult,
    RemoveLabResult,
    UpdateLabResult,
    SaveProductsToClient,
    IssueProductToClient,
    RemoveProductsFromClient,
    UpdateWarehouseSpot,
    RemoveUser,
    SwapSpot,
}