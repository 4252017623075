import React from 'react'
import PropTypes from 'prop-types'
import Select from 'react-select'
import AsyncSelect from 'react-select/async'

const defaultNoOptionsMessage = () => 'Valikud puuduvad'

const SelectInput = ({
  label,
  value,
  options,
  onChange,
  onInputChange,
  error,
  filterOption,
  getOptionLabel,
  getOptionValue,
  loadingMessage,
  noOptionsMessage,
  defaultInputValue,
  defaultValue,
  load,
  formatOptionLabel,
  components,
  placeholder,
  isClearable,
}) => (
    <div className={`input-wrapper input-wrapper--select${error ? ' input-wrapper--error' : ''}`}>
      <label>
        { label && <span>{ label }</span> }
        {
          load ?
          <AsyncSelect
            loadOptions={load}
            getOptionLabel={getOptionLabel}
            getOptionValue={getOptionValue}
            loadingMessage={loadingMessage}
            noOptionsMessage={noOptionsMessage || defaultNoOptionsMessage}
            value={value}
            onChange={onChange}
            defaultInputValue={defaultInputValue}
            defaultValue={defaultValue}
            formatOptionLabel={formatOptionLabel}
            components={components}
            placeholder={placeholder || 'Vali'}
          />
          :
          <Select
            options={options}
            onChange={onChange}
            value={value}
            isClearable={isClearable}
            getOptionLabel={getOptionLabel}
            getOptionValue={getOptionValue}
            onInputChange={onInputChange}
            loadingMessage={loadingMessage}
            noOptionsMessage={noOptionsMessage || defaultNoOptionsMessage}
            filterOption={filterOption}
            formatOptionLabel={formatOptionLabel}
            components={components}
            placeholder={placeholder || 'Vali'}
          />
        }
      </label>
      { error &&
        <div className="input-error">{ error }</div>
      }
    </div>
)

SelectInput.propTypes = {
    label: PropTypes.string,
    value: PropTypes.any,
    options: PropTypes.array,
    onChange: PropTypes.func,
    onInputChange: PropTypes.func,
    error: PropTypes.string,
    filterOption: PropTypes.object,
    getOptionLabel: PropTypes.func,
    getOptionValue: PropTypes.func,
    loadingMessage: PropTypes.string,
    noOptionsMessage: PropTypes.string,
    defaultInputValue: PropTypes.string,
    defaultValue: PropTypes.string,
    load: PropTypes.func,
    formatOptionLabel: PropTypes.func,
    components: PropTypes.array,
    placeholder: PropTypes.string,
    isClearable: PropTypes.bool,
}

export default SelectInput