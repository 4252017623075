/* eslint-disable no-unused-vars */

import React, { useMemo, useState } from 'react'
import { useQuery } from 'urql'
import './style.scss'
import { useTranslation } from 'react-i18next'
import AddWarehouseModal from '../../components/AddWarehouseModal'
import { GetWarehouses } from '../../graphql/queries'
import LoadingComponent from '../../components/Loading'
import WarehouseScan from '../../components/WarehouseScan'

const WarehouseScreen = () => {

    const { t } = useTranslation()
    const [showAddModal, setShowAddModal] = useState(false)
    const [selectedWarehouse, setSelectedWarehouse] = useState(null)

    const [result, refetch] = useQuery({
        query: GetWarehouses,
        variables: {
            page: 0,
            limit: 100,
            orderBy: 'name',
        },
    })

    const handleAddSuccess = () => {
        refetch()
        setShowAddModal(false)
    }

    const renderWarehouses = useMemo(() => {
        if (result.fetching || !result.data?.getWarehouses?.length) return <></>

        return (
            <div className='warehouse-content'>
                <p>{ t('warehouse.choose_warehouse') }</p>

                <div className='warehouse-content--warehouses'>
                    {
                        result.data.getWarehouses.map(x =>
                            <div
                                className='warehouse-content--warehouses-item'
                                key={x.id}
                                onClick={() => setSelectedWarehouse(x)}
                            >
                                { x.name }
                            </div>    
                        )
                    }
                </div>
            </div>
        )
    }, [result?.fetching])

    if (result.fetching) return <LoadingComponent />

    return (
        <div className='warehouse'>
            {
                selectedWarehouse ?
                <WarehouseScan
                    warehouse={selectedWarehouse}
                    onBack={() => setSelectedWarehouse(null)}
                />
                :
                renderWarehouses
            }

            <AddWarehouseModal
                show={showAddModal}
                onSuccess={handleAddSuccess}
                close={() => setShowAddModal(false)}
            />
        </div>
    )
}

export default WarehouseScreen