const maxProducts = 21

const getSpotClass = (products, capacity) => {
    const maxCapacity = capacity ?? maxProducts
    if (!products || products.length < 1) return ''
    if (products.length > 0 && products.length < maxCapacity) return 'has-items'
    if (products.length > maxCapacity) return 'over-limit'
    return 'full'
}

const getWarehouseSpotWeight = (products) => {
    if (!products || products.length < 1) return '0 kg'
    return `${products.reduce((acc, curr) => acc + curr.product.weight, 0)} kg`
}

const getWarehouseSpotAverageWeight = (products) => {
    if (!products || products.length < 1) return '0 kg'
    const total = products.reduce((acc, curr) => acc + curr.product.weight, 0)
    return `${(total / products.length).toFixed(2)} kg`
}

const getTonnes = (value) => value ? value / 1000 : 0

export {
    maxProducts,
    getSpotClass,
    getWarehouseSpotWeight,
    getWarehouseSpotAverageWeight,
    getTonnes,
}