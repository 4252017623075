/* eslint-disable no-unused-vars */
import React, { useEffect, useMemo, useState } from 'react'
import { useQuery, useSubscription } from 'urql'
import './style.scss'
import { GetWarehouses } from '../../graphql/queries'
import LoadingComponent from '../../components/Loading'
import WarehouseSpotModal from '../../components/WarehouseSpotModal'
import WarehouseSpot from '../../components/WarehouseSpot'
import AddLabResultModal from '../../components/AddLabResultModal'
import { WarehouseSpotRemoved, WarehouseSpotUpdated } from '../../graphql/subscriptions'
import WarehouseSpotFilters from '../../components/WarehouseSpotFilters'
import Stats from '../../components/Stats'

const getFilteredSpots = (spots, filters) => {
    if (!spots?.length || !filters) return spots

    let filteredSpots = spots
    Object.entries(filters).forEach(([key, value]) => {
        if (['maxProtein', 'maxFat', 'maxTvbn', 'maxMoisture', 'maxSodium'].includes(key) && filters[key]) {
            filteredSpots = filteredSpots.filter(x => x.currentLabResult && x.currentLabResult[key] && x.currentLabResult[key] <= value)
        }
        if (['minProtein', 'minFat', 'minTvbn', 'minMoisture', 'minSodium'].includes(key) && filters[key]) {
            filteredSpots = filteredSpots.filter(x => x.currentLabResult && x.currentLabResult[key] && x.currentLabResult[key] >= value)
        }
        if (key === 'noOrders' && filters[key]) {
            filteredSpots = filteredSpots.filter(x => x.currentLabResult && x.currentLabResult && (!x.currentLabResult.bookedProducts || x.currentLabResult.bookedProducts.length < 1))
        }
        if (key === 'weight' && filters[key]) {
            filteredSpots = filteredSpots.filter(x => x.products?.length && x.products.some(i => i.product.weight === parseInt(filters[key], 10)))
        }
        if (key === 'minAntioxidant' && filters[key]) {
            filteredSpots = filteredSpots.filter(x => x.currentLabResult && x.currentLabResult.antioxidant && x.currentLabResult.antioxidant >= value)
        }
        if (key === 'maxAntioxidant' && filters[key]) {
            filteredSpots = filteredSpots.filter(x => x.currentLabResult && x.currentLabResult.antioxidant && x.currentLabResult.antioxidant <= value)
        }
        if (key === 'startDate' && filters[key]) {
            filteredSpots = filteredSpots.filter(x => x.products?.length && x.products.some(i => new Date(i.updatedAt) >= new Date(filters[key])))
        }
        if (key === 'endDate' && filters[key]) {
            filteredSpots = filteredSpots.filter(x => x.products?.length && x.products.some(i => new Date(i.updatedAt) <= new Date(filters[key])))
        }
    })

    return filteredSpots
}

const DashboardScreen = () => {

    const [selectedSpot, setSelectedSpot] = useState(null)
    const [selectedWarehouse, setSelectedWarehouse] = useState(null)
    const [warehouses, setWarehouses] = useState()
    const [showSpotModal, setShowSpotModal] = useState(false)
    const [showLabResultModal, setShowLabResultModal] = useState(false)
    const [extraFilters, setExtraFilters] = useState()

    const [result, refetch] = useQuery({
        requestPolicy: 'cache-and-network',
        query: GetWarehouses,
        variables: {
            page: 0,
            limit: 100,
            orderBy: 'name',
        },
    })

    useEffect(() => {
        if (
            result.fetching
            || !result.data?.getWarehouses?.length
        ) return

        setWarehouses(result.data.getWarehouses)
        setSelectedWarehouse(result.data.getWarehouses[0])
    }, [result.fetching])

    useSubscription({
        query: WarehouseSpotRemoved,
    }, (_, updatedData) => {
        const removedId = updatedData?.warehouseSpotRemoved

        if (!removedId) return

        let updatedWarehouseId = null

        const updatedWarehouses = warehouses.map(x => {
            if (x.spots.some(i => i.id === removedId)) {
                updatedWarehouseId = x.id

                return {
                    ...x,
                    spots: x.spots.filter(i => i.id !== removedId)
                }
            }

            return x
        })

        setWarehouses(updatedWarehouses)
        
        if (updatedWarehouseId && selectedWarehouse?.id === updatedWarehouseId) {
            setSelectedWarehouse(updatedWarehouses.find(i => i.id === updatedWarehouseId))
        }
    })

    useSubscription(
        {
            pause: !selectedWarehouse,
            query: WarehouseSpotUpdated,
            variables: {
                id: selectedWarehouse?.id ? selectedWarehouse.id : null,
            },
        },
        (prev, updatedData) => {
            console.log(updatedData)
            const { previous, next } = updatedData.warehouseSpotUpdated

            const updatedList = selectedWarehouse.spots.map(x => {
                
                if (previous && previous.id === x.id) {
                    console.log('updating previous spot:')
                    return previous
                }
                if (next && next.id === x.id) {
                    console.log('updating next spot:')
                    return next
                }
                return x
            })

            setSelectedWarehouse({
                ...selectedWarehouse,
                spots: updatedList,
            })

            console.log('warehouse updated')

            // refetch()
        },
    )

    const handleFilterChange = (filters) => {
        setExtraFilters(filters)
    }

    useEffect(() => {
        handleFilterChange()
    }, [])

    const handleAddLabResult = () => {
        setShowSpotModal(false)
        setShowLabResultModal(true)
    }

    const renderWarehouse = useMemo(() => {
        if (!selectedWarehouse?.spots) return <></>

        return (
            <>
                <div className='dashboard--warehouse-content'>
                    {
                        getFilteredSpots(selectedWarehouse.spots, extraFilters).map(x =>
                            <WarehouseSpot
                                key={x.id}
                                data={x}
                                onClick={(spot) => {
                                    setSelectedSpot(spot)
                                    setShowSpotModal(true)
                                }}
                            />  
                        )
                    }
                </div>
                <Stats
                    warehouse={selectedWarehouse}
                />
            </>
        )
    }, [selectedWarehouse?.spots, extraFilters])

    if (result.fetching) return <LoadingComponent />

    return (
        <div className='dashboard'>
            {
                warehouses && warehouses.length > 0 ?
                <div className='dashboard-warehouse-list'>
                    {
                        warehouses.map(warehouse =>
                            <div
                                key={warehouse.id}
                                className={`dashboard-warehouse${warehouse.id === selectedWarehouse?.id ? ' active' : ''}`}
                                onClick={() => setSelectedWarehouse(warehouse)}
                            >
                                <span>{ warehouse.name }</span>
                            </div>
                        )
                    }
                </div>
                :
                <></>
            }

            <WarehouseSpotFilters
                onFilterChange={handleFilterChange}
            />

            { renderWarehouse }

            <WarehouseSpotModal
                show={selectedSpot !== null && showSpotModal}
                close={() => {
                    setSelectedSpot(null)
                    setShowSpotModal(false)
                }}
                spotId={selectedSpot?.id}
                onAddLabResult={handleAddLabResult}
            />

            <AddLabResultModal
                selectedSpot={selectedSpot}
                show={showLabResultModal}
                onSuccess={handleAddLabResult}
                close={() => setShowLabResultModal(false)}
            />
        </div>
    )
}

export default DashboardScreen