import React from 'react'
import ReactDOM from 'react-dom/client'

import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import HttpBackend from 'i18next-http-backend'
import LanguageDetector from 'i18next-browser-languagedetector'
import I18NextChainedBackend from 'i18next-chained-backend'
import resourcesToBackend from 'i18next-resources-to-backend'
import translations from './translations'

import 'react-datepicker/dist/react-datepicker.css'
import './style/main.scss'

import App from './App'
import reportWebVitals from './reportWebVitals'

const bundledResources = {
  et: {
    translations: translations.et,
  },
}

i18n
  .use(I18NextChainedBackend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    ns: ['translations'],
    defaultNS: 'translations',
    compatibilityJSON: 'v3',
    fallbackLng: 'et',
    partialBundledLanguages: true,
    interpolation: {
      escapeValue: false,
    },
    backend: {
      backends: [
        HttpBackend,
        resourcesToBackend(bundledResources),
      ],
      backendOptions: [
        {
          // loadPath: 'https://eau.nordro.se/translations/{{lng}}.json',
          crossDomain: true,
        },
      ],
    },
  }
)

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <App />
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
