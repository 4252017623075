import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useMutation } from 'urql'
import Button from './Button'
import Input from './Input'
import Modal from './Modal'
import { Responses } from '../util/consts'
import { CreateClient } from '../graphql/mutations'
import BoxNotification from './BoxNotification'

const initialState = {
    name: '',
    email: '',
    phone: '',
    address: '',
}

const initialErrorsState = {
    name: null,
    email: null,
    role: null,
    generic: null,
}

const AddClientModal = ({
    show,
    close,
    onSuccess,
}) => {

    const { t } = useTranslation()
    const [item, setItem] = useState(initialState)
    const [errors, setErrors] = useState(initialErrorsState)
    const [, addClient] = useMutation(CreateClient)
    const [loading, setLoading] = useState(false)

    const hasInputErrors = () => {
        let hasErrors = false
        const errorState = {
            ...initialErrorsState,
        }

        if (!item.name) {
            hasErrors = true
            errorState.name = t('users.fill_field')
        }

        setErrors({
            ...errorState,
        })
        return hasErrors
    }

    const handleAdd = async () => {
        if (hasInputErrors()) return
        setLoading(true)
        try {
            const { data } = await addClient(item)

            if (!data?.createClient || data.createClient !== Responses.Success) {
                setErrors({
                    ...initialErrorsState,
                    generic: data.createClient === Responses.Exists ? t('clients.client_exists') : t('clients.add_error'),
                })
                return
            }

            if (onSuccess) onSuccess(data.createClient)
        } catch (err) {
            setErrors({
                ...initialErrorsState,
                generic: t('clients.add_error'),
            })
            console.log(err)
        } finally {
            setLoading(false)
        }
    }

    const handleClose = () => {
        if (close) close()
        setErrors(initialErrorsState)
        setItem(initialState)
    }

    const setField = (field, value) => {
        setErrors({
            ...errors,
            [field]: null,
        })
        setItem({
            ...item,
            [field]: value,
        })
    }

    return (
        <Modal
            className={'modal-client'}
            show={show}
            close={handleClose}
            title={ t('clients.add_title') }
        >
            <Input
                label={ t('clients.name') }
                placeholder={ t('clients.name') }
                value={item.name}
                onChange={(e) => setField('name', e.target.value)}
                error={errors.name}
            />
            <Input
                label={ t('clients.email') }
                value={item.email}
                onChange={(e) => setField('email', e.target.value)}
                error={errors.email}
            />
            <Input
                label={ t('clients.phone') }
                value={item.phone}
                onChange={(e) => setField('phone', e.target.value)}
                error={errors.phone}
            />
            <Input
                label={ t('clients.address') }
                value={item.address}
                onChange={(e) => setField('address', e.target.value)}
                error={errors.address}
            />
            {
                errors?.generic ?
                <BoxNotification
                    message={errors.generic}
                    type={'error'}
                />
                :
                <></>
            }

            <Button
                label={ t('clients.add_new') }
                onClick={handleAdd}
                loading={loading}
            />
        </Modal>
    )
}

AddClientModal.propTypes = {
    show: PropTypes.bool,
    close: PropTypes.func,
    onSuccess: PropTypes.func,
}

export default AddClientModal