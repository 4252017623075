/* eslint-disable */

import React, { useRef, useState, forwardRef, useImperativeHandle, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useQuery } from 'urql'
import { useTranslation } from 'react-i18next'
import { useInView } from 'react-intersection-observer'
import LoadingComponent from './Loading'
import ChevronBoldIcon from './icons/chevron-bold'
import EmptyList from './EmptyList'

// TODO: Proper infinite scroll fetching
const FilterTable = forwardRef(({
    query,
    queryKey,
    queryOptions,
    fieldConditions,
    includeFields,
    initialOrderBy,
    onRowClick,
    onRowDoubleClick,
    extraFilters,
    statusColorEnabled,
    activeRows,
    rowBackgroundColor,
    rowClass,
    ignoredKeys,
    useExtraFilters,
    subscriptions,
    onFiltersChanged,
    initialSort,
    hideInputs,
    allowSelect,
    queryContext,
}, ref) => {

    const { t } = useTranslation()
    const [bottomRef, inView, entry] = useInView({
        /* Optional options */
        threshold: 0,
    })

    const [items, setItems] = useState([])
    const [filters, setFilters] = useState([])
    const [orderBy, setOrderBy] = useState(initialOrderBy)
    const [sort, setSort] = useState(initialSort || 'desc')
    const table = useRef()
    const page = useRef(0)
    const limit = useRef(100)
    const extraFiltersRef = useRef()
    const [inputs, setInputs] = useState({})
    const [selectedRow, setSelectedRow] = useState(null)

    const [result, refetch] = useQuery({
        query,
        requestPolicy: 'cache-and-network',
        context: queryContext,
        variables: {
            ...queryOptions?.variables,
            page: page.current,
            limit: limit.current,
            sort,
            filters,
            orderBy,
            extraFilters,
        },
    })

    useEffect(() => {
        if (inView && (items.length % limit.current) === 0) {
            console.log('fetching more')
            refetch({
                requestPolicy: 'network-only',
                variables: {
                    ...queryOptions?.variables,
                    page: page.current + 1,
                    limit: limit.current,
                    sort,
                    filters,
                    orderBy,
                    ...(extraFilters && {
                        extraFilters,
                    }),
                },
            })

            limit.current += limit.current
        }
        
    }, [inView, entry])

    useEffect(() => {
        if (!result.fetching && result.data && result.data[queryKey]) {
            setItems(result.data[queryKey])
        }
    }, [result])

    useEffect(() => {
        // if (onFiltersChanged) onFiltersChanged()

        if (includeFields && !hideInputs) {
            const inputFields = {}
            includeFields.map(x => {
                inputFields[x.value] = ''
                return x
            })
            setInputs(inputFields)
        }
    }, [])

    useEffect(() => {
        if (subscriptions?.add?.id && !items.some(x => x.id === subscriptions.add.id)) {
            setItems([subscriptions.add, ...items])
        }
        if (subscriptions?.update?.id && items.some(x => x.id === subscriptions.update.id)) {
            const itemIndex = items.findIndex(x => x.id === subscriptions.update.id)
            const itemsCopy = [...items]
            itemsCopy[itemIndex] = {
                ...itemsCopy[itemIndex],
                ...subscriptions.update,
            }

            setItems(itemsCopy)
        }
        if (subscriptions?.remove && items.some(x => x.id === subscriptions.remove)) {
            setItems(items.filter(x => x.id !== subscriptions.remove))
        }
    }, [subscriptions])

    useEffect(() => {
        if (!useExtraFilters || !extraFilters || result.fetching) return

        console.log('extra filters', extraFilters)
        
        if (!Object.is(extraFiltersRef.current, extraFilters)) {

            console.log('refetch')
            
            refetch({
                requestPolicy: 'network-only',
                variables: {
                    ...queryOptions?.variables,
                    page: page.current,
                    limit: limit.current,
                    sort,
                    filters,
                    orderBy,
                    ...(extraFilters && {
                        extraFilters,
                    }),
                },
            })
            extraFiltersRef.current = extraFilters
        }
    }, [extraFilters])

    const handleFiltersChanged = () => {
        refetch({
            requestPolicy: 'network-only',
            variables: {
                page: page.current,
                limit: limit.current,
                filters,
                sort,
                orderBy,
                ...(extraFilters && {
                    extraFilters,
                }),
            },
        })
    }

    useImperativeHandle(ref, () => ({
        refresh () {
            handleFiltersChanged()
        },
        getTableRows () {
            return table.current.rows
        },
        getTableRowsData () {
            return result.data[queryKey]?.rows
        },
        getCurrentPage () {
            return page.current
        },
    }))

    const getColValue = (row, field) => {
        const conditionResult = fieldConditions ? fieldConditions(row, field) : null
        
        if (conditionResult) return conditionResult

        return typeof row[field] === 'object' ? '' : row[field]
    }

    const handleFiltering = (field, value) => {
        if (value <= 1 || value.length <= 1) {
            const removed = filters.filter(x => x.field !== field)
            setFilters(removed)
            return
        }
        const filterIndex = filters.findIndex(x => x.field === field)
        if (filterIndex > -1) {
            const newFilters = filters
            newFilters[filterIndex] = {
                field,
                value,
            }
            setFilters(newFilters)
            return
        }
        const newFilters = [
            ...filters,
            {
                field,
                value,
            },
        ]
        setFilters(newFilters)
    }

    const handleSort = (key) => {
        console.log('handleSort', key)
        if (ignoredKeys?.includes(key)) return
        const newSort = sort === 'asc' ? 'desc' : 'asc'
        setOrderBy(key)
        setSort(newSort)

        // refetch()
    }

    const handleEnter = (e) => {
        if (e.key === 'Enter') handleFiltersChanged()
    }

    if (
        !items
        || items.length < 1
    ) return <EmptyList />

    return (
        <div className='filter-table'>
            <table id="filter-table" ref={table}>
                <thead>
                    <tr>
                        {
                            includeFields.map((header) =>
                                <th key={`filter-table-header-${header.value}`}>
                                    <div className='filter-table-header--label' onClick={() => handleSort(header.value)}>
                                        <span>{ t(header.label) }</span>
                                        {
                                            header.value === orderBy ?
                                            <div className={`filter-table-header--sort filter-table-header--sort-${sort === 'asc' ? 'asc' : 'desc'}`}>
                                                <ChevronBoldIcon />
                                            </div>
                                            :
                                            <></>
                                        }
                                    </div>
                                    {
                                        hideInputs ?
                                        <></>
                                        :
                                        <input
                                            value={inputs[header.value]}
                                            onChange={(e) => {
                                                setInputs({
                                                    ...inputs,
                                                    [header.value]: e.target.value,
                                                })
                                                handleFiltering(header.value, e.target.value)
                                            }}
                                            onKeyUp={handleEnter}
                                        />
                                    }
                                </th>
                            )
                        }
                    </tr>
                </thead>
                <tbody>
                    {
                        items.map((row, index) =>
                            <tr
                                key={`filter-table-row-${index}`}
                                className={
                                    `${Array.isArray(activeRows) && activeRows.some(x => x.id === row.id) ? 'filter-table-row--selected ' : ''}${rowClass ? rowClass(row) : ''}`
                                }
                                style={{
                                    ...(statusColorEnabled && rowBackgroundColor && {
                                        backgroundColor: rowBackgroundColor(row),
                                    }),
                                }}
                                onClick={(e) => {
                                    if (onRowClick) {
                                        if (allowSelect && e.shiftKey && selectedRow) {
                                            const first = result.data[queryKey].rows.indexOf(selectedRow)
                                            const currentIndex = index
                                            let selectedItems = []
                                            
                                            if (first > currentIndex) selectedItems = result.data[queryKey].rows.filter((x, i) => i <= first && i >= currentIndex)
                                            if (first < currentIndex) selectedItems = result.data[queryKey].rows.filter((x, i) => i >= first && i <= currentIndex)

                                            onRowClick(selectedItems)
                                            return
                                        }
                                        setSelectedRow(row)
                                        onRowClick(row)
                                    }
                                }}
                                onDoubleClick={(e) => {
                                    if (onRowDoubleClick) onRowDoubleClick(row, e)
                                }}
                            >
                                {
                                    includeFields.map((field, fieldIndex) =>
                                        <td key={`filter-table-row-${index}-col-${fieldIndex}`}>
                                            { getColValue(row, field.value) }
                                        </td>
                                    )
                                }
                            </tr>
                        )
                    }
                </tbody>
            </table>
            <div ref={bottomRef} className='filter-table--bottom'>
                {
                    result.fetching ? <LoadingComponent /> : <></>
                }
            </div>
        </div>
    )
})

FilterTable.displayName = 'FilterTable'

FilterTable.propTypes = {
    query: PropTypes.string,
    queryKey: PropTypes.string,
    queryOptions: PropTypes.object,
    fieldConditions: PropTypes.func,
    includeFields: PropTypes.array,
    initialOrderBy: PropTypes.string,
    onRowClick: PropTypes.func,
    onRowDoubleClick: PropTypes.func,
    extraFilters: PropTypes.object,
    statusColorEnabled: PropTypes.bool,
    activeRows: PropTypes.array,
    rowBackgroundColor: PropTypes.func,
    rowClass: PropTypes.string,
    ignoredKeys: PropTypes.array,
    useExtraFilters: PropTypes.bool,
    subscriptions: PropTypes.object,
    onFiltersChanged: PropTypes.func,
    initialSort: PropTypes.string,
    hideInputs: PropTypes.bool,
    allowSelect: PropTypes.bool,
}

export default FilterTable