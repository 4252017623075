import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import ReactDOM from 'react-dom'
import Button from './Button'
import BoxNotification from './BoxNotification'

const ConfirmModal = ({
    show,
    close,
    title,
    children,
    className,
    onConfirm,
    confirmLabel,
    cancelLabel,
    error,
    loading,
}) => {

    const handleEsc = (e) => {
        if (e.key === 'Escape') {
            close()
        }
    }

    useEffect(() => {
        document.body.addEventListener('keyup', handleEsc)

        return () => {
            document.body.removeEventListener('keyup', handleEsc)
        }
    }, [])

    const handleConfirm = () => {
        if (onConfirm) onConfirm()
        close()
    }

    return ReactDOM.createPortal(
        <>
        { show &&
            <>
                <div onClick={close} className='modal-background'></div>
                <div
                    className={`modal-inner${className ? ` ${className}` : ''}`}
                >
                    <div className='modal-header'>
                        <div className='modal-header--inner'>
                            <button className="modal-close" onClick={close}></button>
                            <h2 className="modal-title">{title}</h2>
                        </div>
                    </div>
                    <div className="modal-content">
                        {children}
                        <BoxNotification message={error} />
                    </div>
                    <div className='modal-actions'>
                        <Button
                            label={cancelLabel || 'Katkesta'}
                            onClick={close}
                            className={'btn-cancel'}
                            disabled={loading}
                        />
                        <Button
                            label={confirmLabel || 'Kinnita'}
                            onClick={handleConfirm}
                            className={'btn-confirm'}
                            disabled={loading}
                            loading={loading}
                        />
                    </div>
                </div>
            </>
        }
        </>,
        document.getElementById('confirm-modal'),
    )
}

ConfirmModal.propTypes = {
    show: PropTypes.bool,
    close: PropTypes.func,
    title: PropTypes.string,
    children: PropTypes.any,
    className: PropTypes.string,
    onConfirm: PropTypes.func,
    confirmLabel: PropTypes.string,
    cancelLabel: PropTypes.string,
    error: PropTypes.string,
    loading: PropTypes.bool,
}

export default ConfirmModal