import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { useAuth } from '../providers/auth'
import { UserRoles } from '../util/consts'
import Button from './Button'

const AppHeader = () => {
    const { t } = useTranslation()
    const { user, signOut } = useAuth()
    const navigate = useNavigate()
    const { pathname } = useLocation()
    const [navOpen, setNavOpen] = useState(false)
    const [subsMenus, setSubMenus] = useState({
        data: false,
        profile: false,
    })

    const toggleMenu = () => setNavOpen(!navOpen)
    const closeMenu = () => setNavOpen(false)

    const handleBrandNavigate = () => {
        if (!user?.role || user.role !== UserRoles.Warehouse) {
            navigate('/')
            return
        }
        navigate('/warehouse')
    }

    const renderAdminMenu = () => (
        <>
            <Link onClick={closeMenu} className={pathname === '/' ? 'active' : ''} to={'/'}>
                { t('header.warehouse') }
            </Link>
            <Link onClick={closeMenu} className={pathname === '/orders' ? 'active' : ''} to={'/orders'}>
                { t('header.orders') }
            </Link>
            <Link onClick={closeMenu} className={pathname === '/lab' ? 'active' : ''} to={'/lab'}>
                { t('header.lab') }
            </Link>
            <Link onClick={closeMenu} className={pathname === '/products' ? 'active' : ''} to={'/products'}>
                { t('header.products') }
            </Link>
            <div className={`nav-sub${subsMenus.data ? ' nav-sub--open' : ''}`}>
                <a
                    className={['/warehouses', '/users'].includes(pathname) ? 'active' : ''}
                    onClick={() => {
                        setSubMenus({ ...subsMenus, data: !subsMenus.data })
                    }}
                >
                    { t('header.data') }
                </a>
                <div className='nav-sub--dropdown'>
                    <Link onClick={closeMenu} className={pathname === '/warehouses' ? 'active' : ''} to={'/warehouses'}>
                        { t('header.warehouses') }
                    </Link>
                    <Link onClick={closeMenu} className={pathname === '/users' ? 'active' : ''} to={'/users'}>
                        { t('header.users') }
                    </Link>
                    <Link onClick={closeMenu} className={pathname === '/clients' ? 'active' : ''} to={'/clients'}>
                        { t('header.clients') }
                    </Link>
                </div>
            </div>
        </>
    )

    const renderUserMenu = () => (
        <>
            {
                user?.role === UserRoles.Lab ?
                <Link onClick={closeMenu} className={pathname === '/lab' ? 'active' : ''} to={'/lab'}>
                    { t('header.lab') }
                </Link>
                :
                <></>
            }
            {
                user?.role === UserRoles.Manager ?
                <Link onClick={closeMenu} className={pathname === '/orders' ? 'active' : ''} to={'/orders'}>
                    { t('header.orders') }
                </Link>
                :
                <></>
            }
            {
                user?.role === UserRoles.Sales ?
                <>
                    <Link onClick={closeMenu} className={pathname === '/orders' ? 'active' : ''} to={'/orders'}>
                        { t('header.orders') }
                    </Link>
                    <Link onClick={closeMenu} className={pathname === '/lab' ? 'active' : ''} to={'/lab'}>
                        { t('header.lab') }
                    </Link>
                    <Link onClick={closeMenu} className={pathname === '/products' ? 'active' : ''} to={'/products'}>
                        { t('header.products') }
                    </Link>
                    <div className={`nav-sub${subsMenus.data ? ' nav-sub--open' : ''}`}>
                        <a
                            className={['/warehouses', '/users'].includes(pathname) ? 'active' : ''}
                            onClick={() => {
                                setSubMenus({ ...subsMenus, data: !subsMenus.data })
                            }}
                        >
                            { t('header.data') }
                        </a>
                        <div className='nav-sub--dropdown'>
                            <Link onClick={closeMenu} className={pathname === '/clients' ? 'active' : ''} to={'/clients'}>
                                { t('header.clients') }
                            </Link>
                        </div>
                    </div>
                </>
                :
                <></>
            }
            {
                user?.role === UserRoles.Warehouse ?
                <></>
                :
                <div className={`nav-sub${subsMenus.reports ? ' nav-sub--open' : ''}`}>
                    <a
                        className={['/profile'].includes(pathname) ? 'active' : ''}
                        onClick={() => {
                            setSubMenus({ ...subsMenus, profile: !subsMenus.profile })
                            navigate(`/profile`)
                        }}
                    >
                        { t('header.profile') }
                    </a>
                </div>
            }
        </>
    )

    return (
        <header className={`app-header${navOpen ? ' nav-open' : ''}`}>
            <div className='app-header--inner'>
                <div className='brands' onClick={handleBrandNavigate}>
                    <div className='brand brand--fishoil'></div>
                    <div className='brand brand--haarslev'></div>
                </div>
                <nav>
                    {
                        user?.role === UserRoles.Admin ?
                        renderAdminMenu()
                        :
                        renderUserMenu()
                    }
                    {
                        user &&
                        <>
                            <div className='nav-sub'>
                                <div className='app-header--info'>
                                    <span className='app-header--info-name'>{ `${(user.name)?.toLowerCase()}` }</span>
                                </div>
                                {
                                    user?.role === UserRoles.Admin ?
                                    <div className='nav-sub--dropdown'>
                                        <Link to={'/profile'}>
                                            { t('header.profile') }
                                        </Link>
                                    </div>
                                    :
                                    <></>
                                }
                            </div>
                            <div className='app-header--actions'>
                                <Button
                                    className={'btn-small'}
                                    label={t('header.sign_out')}
                                    onClick={signOut}
                                />
                            </div>
                        </>
                    }
                </nav>
                <div className="menu-btn" onClick={toggleMenu}>
                    <div></div>
                </div>
            </div>
        </header>
    )
}

export default AppHeader