/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from 'react'
import './style.scss'
import { useTranslation } from 'react-i18next'
import { useSubscription } from 'urql'
import AddLabResultModal from '../../components/AddLabResultModal'
import ScreenHeader from '../../components/ScreenHeader'
import Button from '../../components/Button'
import { GetLabResults } from '../../graphql/queries'
import { LabResultAdded, LabResultRemoved, LabResultUpdated } from '../../graphql/subscriptions'
import FilterTable from '../../components/FilterTable'
import EditLabResultModal from '../../components/EditLabResultModal'
import { formatDate } from '../../util/format'
import LabFilters from '../../components/LabFilters'
import { useAuth } from '../../providers/auth'
import { UserRoles } from '../../util/consts'

const includeFields = [
    {
        value: 'warehouse',
        label: 'Ladu',
    },
    {
        value: 'spot',
        label: 'Laokoht',
    },
    {
        value: 'createdAt',
        label: 'Kuupäev',
    },
    {
        value: 'loadStart',
        label: 'Täitmise algus',
    },
    {
        value: 'loadEnd',
        label: 'Täitmise lõpp',
    },
]

const LabScreen = () => {

    const { t } = useTranslation()
    const { user } = useAuth()
    const tableRef = useRef()
    const [showAddModal, setShowAddModal] = useState(false)
    const [showEditModal, setShowEditModal] = useState(false)
    const [selectedRow, setSelectedRow] = useState(null)
    const [subActive, setSubActive] = useState(false)
    const [addedResult, setAddedResult] = useState(null)
    const [updatedResult, setUpdatedResult] = useState(null)
    const [removedResult, setRemovedResult] = useState(null)
    const [extraFilters, setExtraFilters] = useState()

    useEffect(() => {
        setSubActive(true)

        return () => {
            setSubActive(false)
            setAddedResult(null)
            setUpdatedResult(null)
            setRemovedResult(null)
        }
    }, [])
    
    useSubscription({
            pause: !subActive,
            query: LabResultAdded,
        },
        (prev, updatedData) => {
            console.log(updatedData)
            setAddedResult(updatedData.labResultAdded)
        },
    )

    useSubscription({
            pause: !subActive,
            query: LabResultUpdated,
        },
        (prev, updatedData) => {
            setUpdatedResult(updatedData.labResultUpdated)
        },
    )

    useSubscription({
            pause: !subActive,
            query: LabResultRemoved,
        },
        (prev, updatedData) => {
            console.log('result removed', updatedData)
            setRemovedResult(updatedData.labResultRemoved)
        },
    )

    const fieldConditions = (row, field) => {
        switch (field) {
            case 'spot':
                return row[field]?.name || '-'
            case 'warehouse':
                return row.spot?.warehouse?.name || '-'
            case 'createdAt':
                return formatDate(row[field], true)
            case 'loadStart':
                return formatDate(row[field], true)
            case 'loadEnd':
                return formatDate(row[field], true)
            default:
                return row[field]
        }
    }

    const handleCloseAdd = () => {
        setShowAddModal(false)
    }

    const handleCloseEdit = () => {
        setSelectedRow(null)
        setShowEditModal(false)
        tableRef?.current?.refresh()
    }

    const handleAddSuccess = async () => {
        handleCloseAdd()
        tableRef?.current?.refresh()
    }

    const handleRowClick = (row) => {
        setShowEditModal(true)
        setSelectedRow(row)
    }

    const handleFilterChange = (filters) => {
        setExtraFilters(filters)
    }

    const renderHeaderActions = () => {
        if (!user?.role || user.role !== UserRoles.Lab && user.role !== UserRoles.Admin) return <></>
        return (
            <Button
                label={t('lab.add_new')}
                onClick={() => setShowAddModal(true)}
            />
        )
    }

    return (
        <div className='lab'>
            <ScreenHeader
                title={ t('lab.title') }
                actions={renderHeaderActions}
            />
            <LabFilters
                onFilterChange={handleFilterChange}
            />
            <FilterTable
                ref={tableRef}
                query={GetLabResults}
                queryKey='getLabResults'
                includeFields={includeFields}
                fieldConditions={fieldConditions}
                initialOrderBy='id'
                hideInputs={true}
                extraFilters={extraFilters}
                useExtraFilters={true}
                onRowClick={handleRowClick}
                subscriptions={{
                    add: addedResult,
                    update: updatedResult,
                    remove: removedResult,
                }}
            />
            <AddLabResultModal
                show={showAddModal}
                onSuccess={handleAddSuccess}
                close={handleCloseAdd}
            />
            <EditLabResultModal
                show={showEditModal}
                initialData={selectedRow}
                onSuccess={handleCloseEdit}
                close={handleCloseEdit}
                onDelete={handleCloseEdit}
            />
        </div>
    )
}

export default LabScreen