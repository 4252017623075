import React, { useMemo } from 'react'
import { Provider } from 'urql'
import NetworkIndicator from './components/NetworkIndicator'
import { useAuth } from './providers/auth'
import Routing from './Routing'
import Urql from './services/urql'
  

const AppWrapper = () => {
    const { user, signOut } = useAuth()

    const onError = (error) => {
        console.log(error.graphQLErrors)

        const isAuthError = error.graphQLErrors.some(x => x.message.includes('INVALID_TOKEN'))

        if (isAuthError) {
            signOut()
        }
    }

    const client = useMemo(() => Urql.init(onError), [user])

    return (
        <Provider value={client}>
            <NetworkIndicator />
            <Routing />
        </Provider>
    )
}

export default AppWrapper