import React, { useEffect } from 'react'
import {
  Routes,
  Route,
  useLocation,
} from 'react-router-dom'
import { useMutation } from 'urql'
import AppHeader from './components/AppHeader'
import { ValidateToken } from './graphql/mutations'
import { useAuth } from './providers/auth'
import ClientsScreen from './screens/Clients'
import DashboardScreen from './screens/Dashboard'
import LabScreen from './screens/Lab'
import LoginScreen from './screens/Login'
import OrdersScreen from './screens/Orders'
import ProductsScreen from './screens/Products'
import ProfileScreen from './screens/Profile'
import StatisticsScreen from './screens/Statistics'
import UsersScreen from './screens/Users'
import WarehouseScreen from './screens/Warehouse'
import WarehousesScreen from './screens/Warehouses'
import { UserRoles } from './util/consts'

const getDefaultRoute = (user) => {
  if (!user?.role) return <LoginScreen />

  switch (user.role) {
    case UserRoles.Admin:
    case UserRoles.Manager:
    case UserRoles.Sales:
    case UserRoles.Lab:
      return <DashboardScreen />
    case UserRoles.Warehouse:
      return <WarehouseScreen />
    case UserRoles.Owner:
      return <StatisticsScreen />
    default:
      return <LoginScreen />
  }
}

const Routing = () => {

  const { user, loaded } = useAuth()
  const { pathname } = useLocation()
  const [, validateUserToken] = useMutation(ValidateToken)

  useEffect(() => {
    const token = localStorage.getItem('token')
    validateUserToken({ token })
  }, [])

  if (!loaded) return <></>

  return (
      <div className='wrapper'>
        {
          user ?
          (
              <div>
                  <AppHeader />
                  <div className={`app-content${pathname && pathname.length > 1 ? ` ${pathname.split('/')[1]}` : ''}`}>
                      <Routes>
                        <Route path='/' element={getDefaultRoute(user)} />
                        <Route path='/users' element={<UsersScreen />} />
                        <Route path='/clients' element={<ClientsScreen />} />
                        <Route path='/profile' element={<ProfileScreen />} />
                        <Route path='/warehouses' element={<WarehousesScreen />} />
                        <Route path='/warehouse' element={<WarehouseScreen />} />
                        <Route path='/products' element={<ProductsScreen />} />
                        <Route path='/lab' element={<LabScreen />} />
                        <Route path='/dashboard' element={<DashboardScreen />} />
                        <Route path='/orders' element={<OrdersScreen />} />
                        <Route path='/stats' element={<StatisticsScreen />} />
                      </Routes>
                  </div>
                  {/* <AppFooter /> */}
              </div>
          )
          :
          (
              <Routes>
                <Route
                    path='*'
                    element={<LoginScreen />}
                />
              </Routes>
          )
        }
      </div>
  )
}

export default Routing