import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useMutation } from 'urql'
import Button from './Button'
import Input from './Input'
import Modal from './Modal'
import { Responses } from '../util/consts'
import { CreateWarehouse } from '../graphql/mutations'
import BoxNotification from './BoxNotification'

const initialState = {
    name: '',
}

const initialErrorsState = {
    name: null,
    generic: null,
}

const AddWarehouseModal = ({
    show,
    close,
    onSuccess,
}) => {

    const { t } = useTranslation()
    const [item, setItem] = useState(initialState)
    const [errors, setErrors] = useState(initialErrorsState)
    const [, addUser] = useMutation(CreateWarehouse)
    const [loading, setLoading] = useState(false)

    const hasInputErrors = () => {
        let hasErrors = false
        const errorState = {
            ...initialErrorsState,
        }

        if (!item.name) {
            hasErrors = true
            errorState.name = t('warehouses.fill_field')
        }

        setErrors({
            ...errorState,
        })
        return hasErrors
    }

    const handleAdd = async () => {
        if (hasInputErrors()) return
        setLoading(true)
        try {
            const { data } = await addUser(item)

            if (!data?.createWarehouse || data.createWarehouse !== Responses.Success) {
                setErrors({
                    ...initialErrorsState,
                    generic: data.createWarehouse === Responses.Exists ? t('warehouses.user_exists') : t('warehouses.add_error'),
                })
                return
            }

            if (onSuccess) onSuccess(data.createWarehouse)
        } catch (err) {
            setErrors({
                ...initialErrorsState,
                generic: t('warehouses.add_error'),
            })
            console.log(err)
        } finally {
            setLoading(false)
        }
    }

    const handleClose = () => {
        if (close) close()
        setErrors(initialErrorsState)
        setItem(initialState)
    }

    const setField = (field, value) => {
        setErrors({
            ...errors,
            [field]: null,
        })
        setItem({
            ...item,
            [field]: value,
        })
    }

    return (
        <Modal
            className={'modal-warehouse'}
            show={show}
            close={handleClose}
            title={ t('warehouses.add_title') }
        >
            <Input
                label={ t('warehouses.name') }
                placeholder={ t('warehouses.name') }
                value={item.name}
                onChange={(e) => setField('name', e.target.value)}
                error={errors.name}
            />

            {
                errors?.generic ?
                <BoxNotification
                    message={errors.generic}
                    type={'error'}
                />
                :
                <></>
            }

            <Button
                label={ t('warehouses.add_new') }
                onClick={handleAdd}
                loading={loading}
            />
        </Modal>
    )
}

AddWarehouseModal.propTypes = {
    show: PropTypes.bool,
    close: PropTypes.func,
    onSuccess: PropTypes.func,
}

export default AddWarehouseModal