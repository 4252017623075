import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useMutation, useQuery } from 'urql'
import Button from './Button'
import SelectInput from './SelectInput'
import { GetWarehouses } from '../graphql/queries'
import { SwapSpot } from '../graphql/mutations'
import { Responses } from '../util/consts'

const ChangeWarehouse = ({
    onSuccess,
    data,
}) => {

    const { t } = useTranslation()
    const [loading, setLoading] = useState(false)
    const [warehouses, setWarehouses] = useState([])
    const [warehouse, setWarehouse] = useState(null)
    const [, changeSpot] = useMutation(SwapSpot)
    const [spot, setSpot] = useState(null)
    const [warehouseQuery] = useQuery({
        query: GetWarehouses,
        variables: {
            page: 0,
            limit: 100,
            orderBy: 'name',
        },
    })

    useEffect(() => {
        if (warehouseQuery.fetching) return

        setWarehouses(warehouseQuery?.data?.getWarehouses ?? [])
    }, [warehouseQuery?.fetching])

    const handleChange = async () => {
        setLoading(true)

        const res = await changeSpot({
            currentId: parseInt(data?.id, 10),
            nextId: parseInt(spot, 10),
        })

        if (res?.data?.swapSpot === Responses.Success && onSuccess) onSuccess()

        setLoading(false)
    }

    return (
        <div
            className={'change-spot'}
        >
            <div className='change-spot--row'>
                <SelectInput
                    label={ t('lab.warehouse') }
                    options={warehouses}
                    value={warehouses.find(x => x.id === warehouse)}
                    getOptionLabel={(option) => t(option.name)}
                    getOptionValue={(option) => option.id}
                    onChange={(option) => setWarehouse(option.id)}
                />
                {
                    warehouse ?
                    <SelectInput
                        label={ t('lab.warehouse_spot') }
                        options={warehouses.find(x => x.id === warehouse).spots}
                        value={spot ? warehouses.find(x => x.id === warehouse).spots.find(x => x.id === spot) : null}
                        getOptionLabel={(option) => t(option.name)}
                        getOptionValue={(option) => option.id}
                        onChange={(option) => setSpot(option.id)}
                    />
                    :
                    <></>
                }
            </div>
            <div className={'modal-actions'}>
                <Button
                    label={ t('warehouse.save_spot') }
                    onClick={handleChange}
                    loading={loading}
                />
            </div>
        </div>
    )
}

ChangeWarehouse.propTypes = {
    onSuccess: PropTypes.func,
    data: PropTypes.object,
}

export default ChangeWarehouse