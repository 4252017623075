import React from 'react'
import PropTypes from 'prop-types'
import ActivityIndicator from './ActivityIndicator'

const Button = ({
  onClick,
  label,
  disabled,
  className,
  loading,
  icon,
  backgroundColor,
}) => (
  <button
    className={`btn${className ? ` ${className}` : ''}${loading ? ` btn--loading` : ''}${icon ? ` btn--has-icon` : ''}`}
    onClick={onClick}
    disabled={disabled || loading}
    style={{
      backgroundColor,
    }}
  >
    {
      icon ?
      <div className={`btn--icon${!label ? ' btn--icon-no-label' : ''}`}>{ icon }</div>
      :
      <></>
    }
    {
      label ?
      <span>{ label }</span>
      :
      <></>
    }
    {
      loading ?
      <ActivityIndicator />
      :
      <></>
    }
  </button>
)

Button.propTypes = {
  onClick: PropTypes.func,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  loading: PropTypes.bool,
  icon: PropTypes.object,
  backgroundColor: PropTypes.string,
}

export default Button