import React from 'react'
import { useTranslation } from 'react-i18next'
import useNetwork from '../hooks/network'
import WifiOffIcon from './icons/wifi-off'

const NetworkIndicator = () => {
    const isOnline = useNetwork()
    const { t } = useTranslation()

    if (isOnline) return <></>

    return (
        <div className='network-indicator'>
            <WifiOffIcon fill={'#fff'} />
            <span>{ t('no_connection') }</span>
        </div>
    )
}

export default NetworkIndicator