import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import { ProvideAuth } from './providers/auth'
import ErrorBoundary from './components/ErrorBoundary'
import AppWrapper from './AppWrapper'

const App = () => (
  <ErrorBoundary>
    <BrowserRouter>
      <ProvideAuth>
        <AppWrapper />
      </ProvideAuth>
    </BrowserRouter>
  </ErrorBoundary>
)

export default App
