import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import Modal from './Modal'

const OrderLabResultModal = ({
    show,
    close,
    data,
    title,
}) => {

    const { t } = useTranslation()

    if (!data) return <></>

    return (
        <Modal
            show={show}
            close={close}
            className={'order-lab-result'}
            title={title}
        >
            <div className='data-row'>
                <div className='data-col'>
                    <span>{ t('lab.protein_min') }</span>
                    <span>{ `${data?.minProtein}` || '0' }</span>
                </div>
                <div className='data-col'>
                    <span>{ t('lab.protein_max') }</span>
                    <span>{ `${data?.maxProtein}` || '0' }</span>
                </div>
            </div>
            <div className='data-row'>
                <div className='data-col'>
                    <span>{ t('lab.moisture_min') }</span>
                    <span>{ `${data?.minMoisture}` || '0' }</span>
                </div>
                <div className='data-col'>
                    <span>{ t('lab.moisture_max') }</span>
                    <span>{ `${data?.maxMoisture}` || '0' }</span>
                </div>
            </div>
            <div className='data-row'>
                <div className='data-col'>
                    <span>{ t('lab.sodium_min') }</span>
                    <span>{ `${data?.minSodium}` || '0' }</span>
                </div>
                <div className='data-col'>
                    <span>{ t('lab.sodium_max') }</span>
                    <span>{ `${data?.maxSodium}` || '0' }</span>
                </div>
            </div>
            <div className='data-row'>
                <div className='data-col'>
                    <span>{ t('lab.fat_min') }</span>
                    <span>{ `${data?.minFat}` || '0' }</span>
                </div>
                <div className='data-col'>
                    <span>{ t('lab.fat_max') }</span>
                    <span>{ `${data?.maxFat}` || '0' }</span>
                </div>
            </div>
            <div className='data-row'>
                <div className='data-col'>
                    <span>{ t('lab.tvbn_min') }</span>
                    <span>{ `${data?.minTvbn}` || '0' }</span>
                </div>
                <div className='data-col'>
                    <span>{ t('lab.tvbn_max') }</span>
                    <span>{ `${data?.maxTvbn}` || '0' }</span>
                </div>
            </div>
            <div className='data-row'>
                <div className='data-col'>
                    <span>{ t('lab.antioxidant') }</span>
                    <span>{ `${data?.antioxidant}` || '0' }</span>
                </div>
            </div>
        </Modal>
    )
}

OrderLabResultModal.propTypes = {
    show: PropTypes.bool,
    close: PropTypes.func,
    data: PropTypes.object,
    title: PropTypes.string,
}

export default OrderLabResultModal