/* eslint-disable */

import React, { useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { useMutation, useQuery } from 'urql'
import { useTranslation } from 'react-i18next'
import { CreateProduct, IssueProductToClient, RemoveProductSpot, SetProductSpot } from '../graphql/mutations'
import { GetProductByCode } from '../graphql/queries'
import ScreenHeader from './ScreenHeader'
import Button from './Button'
import ScanIcon from './icons/scan'
import LoadingComponent from './Loading'
import DeleteIcon from './icons/delete'
import ScannerInput from './ScannerInput'
import { Responses, isDev } from '../util/consts'
import Input from './Input'

const WarehouseScan = ({
    warehouse,
    onBack,
}) => {
    const { t } = useTranslation()
    const [code, setCode] = useState(isDev ? '3770003625605' : '')
    const [hasScanned, setHasScanned] = useState(false)
    const [weight, setWeight] = useState(1150)
    const [previousSpot, setPreviousSpot] = useState(null)
    const [, setSpot] = useMutation(SetProductSpot)
    const [, removeSpot] = useMutation(RemoveProductSpot)
    const [, issueToClient] = useMutation(IssueProductToClient)
    const [, addProduct] = useMutation(CreateProduct)
    const [productResult, refetchProduct] = useQuery({
        query: GetProductByCode,
        variables: {
            code,
        },
        requestPolicy: 'network-only',
    })

    const handleInput = (input) => {
        setHasScanned(true)
        setCode(input)
    }

    const handleSpotClick = async (spot) => {
        if (
            productResult.data?.getProductByCode?.productSpot?.labResult?.bookedProducts?.length
            && productResult.data?.getProductByCode?.productSpot?.labResult?.bookedProducts.some(x => x.amount !== x.sentProducts.length)
            || !spot?.id
        ) return

        setPreviousSpot(spot)

        let newProduct = null

        if (!productResult.data?.getProductByCode?.code) {
            if (!code || !weight) return

            const itemData = {
                code,
                weight: parseFloat(weight),
            }

            const { data } = await addProduct(itemData)

            if (
                !data?.createProduct
                || [Responses.Failed, Responses.Exists].includes(data.createProduct)
            ) return

            newProduct = parseInt(data.createProduct)
        }

        const res = await setSpot({
            productId: newProduct || productResult.data?.getProductByCode?.id,
            spotId: parseInt(spot.id),
        })

        console.log(res)

        if (res?.data?.setProductSpot && res.data.setProductSpot === Responses.Success) {
            refetchProduct()
        }
    }

    const handleSpotRemove = async () => {
        const res = await removeSpot({
            productId: productResult.data?.getProductByCode?.id, 
        })

        if (res?.data?.removeProductSpot && res.data.removeProductSpot === Responses.Success) {
            refetchProduct()
        }
    }

    const handleIssueClient = async (booking) => {
        const res = await issueToClient({
            productId: productResult.data?.getProductByCode?.id,
            bookId: booking?.id,
        })

        if (res?.data?.issueProductToClient && res.data.issueProductToClient === Responses.Success) {
            refetchProduct()
        }
    }

    const isSelectedSpot = (spotId) => productResult?.data?.getProductByCode?.productSpot?.spot
            && productResult.data.getProductByCode.productSpot.spot.id === spotId ? ' current' : ''

    const renderHeaderActions = () => (
        <>
            <Button
                label={t('warehouse.change_warehouse')}
                onClick={onBack}
            />
        </>
    )

    const renderClients = () => {
        if (!productResult.data?.getProductByCode?.productSpot?.labResult?.bookedProducts?.length) return <></>

        const filteredClients = productResult.data.getProductByCode.productSpot.labResult.bookedProducts.filter(x => x.amount !== x.sentProducts.length)

        return (
            filteredClients.map(i =>
                <Button
                    key={i.id}
                    className={`btn--issue-spot`}
                    label={`${t('warehouse.issue_client')}: ${i.client.name}`}
                    onClick={() => handleIssueClient(i)}
                />
            )
        )
    }

    const renderRemove = () => {
        return (
            !productResult.data?.getProductByCode?.productSpot
            || productResult.data?.getProductByCode?.productSpot?.labResult?.bookedProducts?.length
            && productResult.data?.getProductByCode?.productSpot?.labResult?.bookedProducts.some(x => x.amount !== x.sentProducts.length) ?
            <></>
            :
            <Button
                className={`btn--remove-spot`}
                label={t('warehouse.remove_spot')}
                onClick={handleSpotRemove}
                icon={<DeleteIcon />}
            />
        )
    }

    const renderSpots = useMemo(() => {
        if (!code) return (
            <div className='warehouse-content--scan-empty'>
                <ScanIcon />
                <h4>{ t('warehouse.scan') }</h4>
            </div>
        )

        if (productResult?.data?.getProductByCode?.clientProduct?.client) return (
            <div className='warehouse-content--product-issued'>
                <p>
                    { t('warehouse.product_issued', { code: productResult.data.getProductByCode.code }) }
                    <span>{ ` ${productResult.data.getProductByCode.clientProduct.client.name}` }</span>
                </p>
            </div>
        )

        if (!productResult?.data?.getProductByCode?.id) {
            return (
                <div className='warehouse-content--product'>
                    <Input
                        label={ t('products.weight') }
                        placeholder={ t('products.weight') }
                        value={weight}
                        type={'number'}
                        suffix={'kg'}
                        onChange={(e) => setWeight(e.target.value)}
                    />
                    <p>{ `Toote kood: ${code}` }</p>
                    
                    { renderRemove() }

                    { renderClients() }

                    {
                        previousSpot ?
                        <div className='warehouse-content--previous-spot'>
                            <p>{ t('warehouse.previous_spot') }</p>
                            <div
                                className={
                                    `warehouse-content--product-spot${isSelectedSpot(previousSpot.id)}`
                                }
                                onClick={() => handleSpotClick(previousSpot)}
                            >
                                { previousSpot.name }
                            </div>
                        </div>
                        :
                        <></>
                    }

                    <p>{ t('warehouse.choose_spot') }</p>
                    <div
                        className='warehouse-content--product-spots'
                    >
                        {
                            warehouse.spots.map(i =>
                                <div
                                    className={
                                        `warehouse-content--product-spot${isSelectedSpot(i.id)}`
                                    }
                                    key={i.id}
                                    onClick={() => handleSpotClick(i)}
                                >
                                    { i.name }
                                </div>    
                            )
                        }
                    </div>
                </div>
            )
        }

        if (!productResult?.data?.getProductByCode?.code) return <></>

        return (
            <div className='warehouse-content--product'>
                <p>{ `Toote kood: ${productResult.data.getProductByCode.code}` }</p>
                
                { renderRemove() }

                { renderClients() }

                {
                    previousSpot ?
                    <div className='warehouse-content--previous-spot'>
                        <p>{ t('warehouse.previous_spot') }</p>
                        <div
                            className={
                                `warehouse-content--product-spot${isSelectedSpot(previousSpot.id)}`
                            }
                            onClick={() => handleSpotClick(previousSpot)}
                        >
                            { previousSpot.name }
                        </div>
                    </div>
                    :
                    <></>
                }

                <p>{ t('warehouse.choose_spot') }</p>
                <div
                    className='warehouse-content--product-spots'
                >
                    {
                        warehouse.spots.map(i =>
                            <div
                                className={
                                    `warehouse-content--product-spot${isSelectedSpot(i.id)}`
                                }
                                key={i.id}
                                onClick={() => handleSpotClick(i)}
                            >
                                { i.name }
                            </div>    
                        )
                    }
                </div>
            </div>
        )
    }, [productResult.data, code, weight])

    if (!warehouse) return <></>
    
    if (productResult.fetching) return <LoadingComponent />

    return (
        <div
            className='warehouse-content--scan'
        >
            <ScreenHeader
                title={`${t('warehouse.current')}: ${warehouse.name}`}
                actions={renderHeaderActions}
            />

            { renderSpots }

            <ScannerInput
                onBarcodeScanned={handleInput}
            />
        </div>
    )
}

WarehouseScan.propTypes = {
    warehouse: PropTypes.object,
    onBack: PropTypes.func,
}

export default WarehouseScan