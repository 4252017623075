import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useMutation, useQuery, useSubscription } from 'urql'
import './style.scss'
import { useTranslation } from 'react-i18next'
import Button from '../../components/Button'
import DeleteIcon from '../../components/icons/delete'
import ScreenHeader from '../../components/ScreenHeader'
import EditUserModal from '../../components/EditUserModal'
import AddWarehouseModal from '../../components/AddWarehouseModal'
import { GetWarehouses } from '../../graphql/queries'
import { WarehouseAdded, WarehouseUpdated } from '../../graphql/subscriptions'
import EmptyList from '../../components/EmptyList'
import Input from '../../components/Input'
import { CreateWarehouseSpot, RemoveWarehouse, RemoveWarehouseSpot, UpdateWarehouseSpot } from '../../graphql/mutations'
import ConfirmModal from '../../components/ConfirmModal'
import { Responses } from '../../util/consts'

const AddSpotForm = ({
    warehouseId,
}) => {
    const { t } = useTranslation()
    const [label, setLabel] = useState('')
    const [maxProducts, setMaxProducts] = useState(21)
    const [loading, setLoading] = useState(false)
    const [, addSpot] = useMutation(CreateWarehouseSpot)

    const handleAddSpot = async () => {
        if (!label) return

        setLoading(true)
        try {
            const res = await addSpot({
                warehouse: warehouseId,
                name: label,
                maxProducts: parseInt(maxProducts, 10),
            })

            console.log(res)
        } catch (err) {
            console.log(err)
        } finally {
            setLoading(false)
        }
    }

    return (
        <div className='warehouses--add-spot'>
            <h5>{t('warehouses.add_spot_title')}</h5>
            <Input
                label={t('warehouses.spot_label')}
                value={label}
                onChange={(e) => setLabel(e.target.value)}
            />
            <Input
                label={t('warehouses.spot_max_products')}
                value={maxProducts}
                onChange={(e) => setMaxProducts(e.target.value)}
            />
            <Button
                label={t('warehouses.add_spot')}
                onClick={handleAddSpot}
                loading={loading}
            />
        </div>
    )
}

AddSpotForm.propTypes = {
    warehouseId: PropTypes.number,
}

const WarehousesScreen = () => {

    const { t } = useTranslation()
    const [items, setItems] = useState()
    const [subActive, setSubActive] = useState(false)
    const [selectedSpot, setSelectedSpot] = useState(null)
    const [showAddModal, setShowAddModal] = useState(false)
    const [showEditModal, setShowEditModal] = useState(false)
    const [showConfirmModal, setShowConfirmModal] = useState(false)

    const [, deleteWarehouse] = useMutation(RemoveWarehouse)
    const [, editSpot] = useMutation(UpdateWarehouseSpot)
    const [, removeSpot] = useMutation(RemoveWarehouseSpot)

    const [result, refetch] = useQuery({
        query: GetWarehouses,
        variables: {
            page: 0,
            limit: 100,
            orderBy: 'name',
        },
    })

    useEffect(() => {
        setSubActive(true)
        return () => {
            setSubActive(false)
            setItems([])
            setShowAddModal(false)
            setShowEditModal(false)
        }
    }, [])

    useEffect(() => {
        if (!result.fetching && result.data?.getWarehouses) {
            setItems(result.data.getWarehouses)
        }
    }, [result.fetching])

    useSubscription(
        {
            query: WarehouseAdded,
            pause: result.fetching || !subActive,
        },
        (prev, data) => {
            const newItem = data?.warehouseAdded ? data.warehouseAdded : undefined

            if (!newItem) return

            setItems([newItem, ...items])
        },
    )

    useSubscription(
        {
            query: WarehouseUpdated,
            pause: result.fetching,
        },
        (prev, data) => {
            const newItem = data?.warehouseUpdated ? data.warehouseUpdated : undefined

            if (!newItem) return

            const itemIndex = items.findIndex((item) => item.id === newItem.id)

            if (itemIndex === -1) return

            const newList = [...items]
            newList[itemIndex] = newItem

            setItems(newList)
        },
    )

    const handleAddSuccess = () => {
        setShowAddModal(false)
    }

    const handleEditSuccess = () => {
        setShowEditModal(false)
    }

    const handleDelete = async (id) => {
        const deleteRes = await deleteWarehouse({
            id: parseInt(id, 10),
        })

        if (
            !deleteRes?.data?.removeWarehouse
            || deleteRes.data.removeWarehouse !== Responses.Success
        ) return

        refetch({
            requestPolicy: 'network-only',
        })
    }

    const handleSpotChange = async (warehouseId, spotId, field, value) => {
        if (!spotId || !warehouseId) return

        const warehouseIndex = items.findIndex((item) => item.id === warehouseId)
        const spotIndex = items[warehouseIndex].spots.findIndex(x => x.id === spotId)
        const itemsCopy = [...items]

        itemsCopy[warehouseIndex].spots[spotIndex][field] = value

        setItems(itemsCopy)

        editSpot({
            id: parseInt(spotId, 10),
            data: {
                [field]: field === 'maxProducts' ? parseInt(value, 10) : value,
            },
        })
    }

    const handleConfirmClose = () => {
        setSelectedSpot(null)
        setShowConfirmModal(false)
    }

    const handleRemoveSpot = async () => {
        if (!selectedSpot?.id) return

        try {
            const res = await removeSpot({
                id: selectedSpot.id,
            })

            if (res?.data?.removeWarehouseSpot !== Responses.Success) return

            refetch({
                requestPolicy: 'network-only',
            })
            handleConfirmClose()
        } catch (err) {
            console.log(err)
        }
    }

    const renderHeaderActions = () => (
        <>
            <Button
                label={t('warehouses.add_new')}
                onClick={() => setShowAddModal(true)}
            />
        </>
    )

    return (
        <div className='warehouses'>
            <ScreenHeader
                title={t('warehouses.title')}
                actions={renderHeaderActions}
            />

            <div className='warehouses-list'>
                {
                    items ?
                        items.map(x =>
                            <div
                                className='warehouses-list--item'
                                key={x.id}
                            >
                                <header>
                                    <h4>{x.name}</h4>
                                    <Button
                                        icon={<DeleteIcon />}
                                        onClick={() => handleDelete(x.id)}
                                    />
                                </header>
                                <div className='warehouses-list--item-spots'>
                                    {
                                        x.spots?.map(i =>
                                            <div
                                                className='warehouses-list--item-spot'
                                                key={`spot-${i.id}`}
                                            >
                                                <Input
                                                    label={t('warehouses.spot_label')}
                                                    value={i.name}
                                                    onChange={(e) => handleSpotChange(x.id, i.id, 'name', e.target.value)}
                                                />
                                                <Input
                                                    label={t('warehouses.spot_max_products')}
                                                    value={i.maxProducts}
                                                    onChange={(e) => handleSpotChange(x.id, i.id, 'maxProducts', e.target.value)}
                                                />
                                                <Button
                                                    label={t('warehouses.spot_remove')}
                                                    onClick={() => {
                                                        setSelectedSpot(i)
                                                        setShowConfirmModal(true)
                                                    }}
                                                />
                                            </div>
                                        )
                                    }
                                </div>
                                <AddSpotForm warehouseId={x.id} />
                            </div>
                        )
                        :
                        <EmptyList />
                }
            </div>

            <AddWarehouseModal
                show={showAddModal}
                onSuccess={handleAddSuccess}
                close={() => setShowAddModal(false)}
            />
            <EditUserModal
                show={showEditModal}
                onSuccess={handleEditSuccess}
                close={() => setShowEditModal(false)}
            />
            <ConfirmModal
                show={showConfirmModal}
                close={handleConfirmClose}
                onConfirm={handleRemoveSpot}
                title={t('warehouses.confirm_delete_spot', { spot: selectedSpot?.name || ''})}
            />
        </div>
    )
}

export default WarehousesScreen