import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { getSpotClass, maxProducts } from '../util/helpers'

const WarehouseSpot = ({
    data,
    onClick,
}) => {

    const { t } = useTranslation()
    const [capacity, setCapacity] = useState(maxProducts)

    useEffect(() => {
        if (!data?.maxProducts) setCapacity(maxProducts)
        setCapacity(data.maxProducts)
    }, [data])

    const handleClick = () => {
        if (onClick) onClick(data)
    }

    if (!data) return <></>

    return (
        <div
            className={
                `dashboard--warehouse-spot${` ${getSpotClass(data.products, data.maxProducts)}`}`
            }
            onClick={handleClick}
        >
            {
                data.currentLabResult?.bookedProducts?.length > 0 ?
                <div className='dashboard--warehouse-spot-sales'></div>
                :
                <></>
            }
            <h5>{ data.name }</h5>
            <div className='dashboard--warehouse-spot-count'>
                <div>{ t('dashboard.bags') }</div>
                <span>{ `${data.products.length} / ${capacity}` }</span>
            </div>
            <div className={`dashboard--warehouse-spot-lab${data?.currentLabResult ? ' dashboard--warehouse-spot-lab--ready' : ''}`}>
                <div>{ `${t('dashboard.lab_result')}` }</div>
            </div>
        </div>
    )
}

WarehouseSpot.propTypes = {
    data: PropTypes.object,
    onClick: PropTypes.func,
}

export default WarehouseSpot