import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useMutation } from 'urql'
import Button from './Button'
import Input from './Input'
import Modal from './Modal'
import { Responses, UserRoles } from '../util/consts'
import SelectInput from './SelectInput'
import { CreateUser } from '../graphql/mutations'
import BoxNotification from './BoxNotification'

const initialState = {
    name: '',
    email: '',
    role: '',
}

const initialErrorsState = {
    name: null,
    email: null,
    role: null,
    generic: null,
}

const userRoles = Object.entries(UserRoles).map(([, value])=> ({
    label: `roles.${value}`,
    value,
}))

const AddProductModal = ({
    show,
    close,
    onSuccess,
}) => {

    const { t } = useTranslation()
    const [item, setItem] = useState(initialState)
    const [errors, setErrors] = useState(initialErrorsState)
    const [, addUser] = useMutation(CreateUser)
    const [loading, setLoading] = useState(false)

    const hasInputErrors = () => {
        let hasErrors = false
        const errorState = {
            ...initialErrorsState,
        }

        if (!item.name) {
            hasErrors = true
            errorState.name = t('users.fill_field')
        }

        if (!item.email) {
            hasErrors = true
            errorState.email = t('users.fill_field')
        }

        if (!item.role) {
            hasErrors = true
            errorState.role = t('users.fill_field')
        }

        setErrors({
            ...errorState,
        })
        return hasErrors
    }

    const handleAdd = async () => {
        if (hasInputErrors()) return
        setLoading(true)
        try {
            const { data } = await addUser(item)

            if (!data?.createUser || data.createUser !== Responses.Success) {
                setErrors({
                    ...initialErrorsState,
                    generic: data.createUser === Responses.Exists ? t('users.user_exists') : t('users.add_error'),
                })
                return
            }

            if (onSuccess) onSuccess(data.createUser)
        } catch (err) {
            setErrors({
                ...initialErrorsState,
                generic: t('users.add_error'),
            })
            console.log(err)
        } finally {
            setLoading(false)
        }
    }

    const handleClose = () => {
        if (close) close()
        setErrors(initialErrorsState)
        setItem(initialState)
    }

    const setField = (field, value) => {
        setErrors({
            ...errors,
            [field]: null,
        })
        setItem({
            ...item,
            [field]: value,
        })
    }

    return (
        <Modal
            className={'modal-user'}
            show={show}
            close={handleClose}
            title={ t('users.add_title') }
        >
            <SelectInput
                label={ t('users.role') }
                options={userRoles}
                value={userRoles.find(x => x.value === item.role)}
                getOptionLabel={(option) => t(option.label)}
                onChange={(option) => setField('role', option.value)}
                error={errors.role}
            />
            <Input
                label={ t('users.name') }
                placeholder={ t('users.name') }
                value={item.name}
                onChange={(e) => setField('name', e.target.value)}
                error={errors.name}
            />
            <Input
                label={ t('users.email') }
                value={item.email}
                onChange={(e) => setField('email', e.target.value)}
                error={errors.email}
            />

            {
                errors?.generic ?
                <BoxNotification
                    message={errors.generic}
                    type={'error'}
                />
                :
                <></>
            }

            <Button
                label={ t('users.add_new') }
                onClick={handleAdd}
                loading={loading}
            />
        </Modal>
    )
}

AddProductModal.propTypes = {
    show: PropTypes.bool,
    close: PropTypes.func,
    onSuccess: PropTypes.func,
}

export default AddProductModal