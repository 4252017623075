import React, { useEffect, useState } from 'react'
import './style.scss'

import { useTranslation } from 'react-i18next'
import { useMutation, useQuery } from 'urql'
import Button from '../../components/Button'
import Input from '../../components/Input'
import { useAuth } from '../../providers/auth'
import { Login } from '../../graphql/mutations'
import { GetWarehouseUsers } from '../../graphql/queries'
import SelectInput from '../../components/SelectInput'

const initialCredentials = {
    email: '',
    password: '',
}

const LoginScreen = () => {

    const { t } = useTranslation()
    const { signIn } = useAuth()
    const [error, setError] = useState()
    const [forgotError, setForgotError] = useState()
    const [forgot, setForgot] = useState()
    const [forgotSent, setForgotSent] = useState()
    const [forgotLoading, setForgotLoading] = useState(false)
    const [credentials, setCredentials] = useState(initialCredentials)
    const [warehouseUsers, setWarehouseUsers] = useState([])
    // const [resetPass] = useMutation(ResetPassword)
    const [, loginWithEmail] = useMutation(Login)

    const [usersQuery] = useQuery({
        query: GetWarehouseUsers,
        fetchPolicy: 'network-only',
    })

    const reset = () => setError(null)

    useEffect(() => {
        console.log('Login')
        signIn()

        return () => reset()
    }, [])

    useEffect(() => {
        if (usersQuery?.fetching) return

        console.log(usersQuery?.data?.users)

        setWarehouseUsers(usersQuery?.data?.getWarehouseUsers || [])
    }, [usersQuery?.fetching])

    const login = async () => {
        try {
            const res = await loginWithEmail({
                email: credentials.email,
                password: credentials.password,
            })

            if (res?.data?.login?.token) {
                console.log(res)
                signIn(res.data.login.token)
                return
            }

            setError(t('login.error'))
        } catch (err) {
            setError(t('login.error'))
            console.log('login: ', err)
        }
    }

    const resetPassword = async () => {
        setForgotLoading(true)
        try {
            // const res = await resetPass({
            //     variables: {
            //         email: credentials.email,
            //     },
            // })

            // if (res?.resetPassword?.status === 'FAIL') {
            //     setForgotError(true)
            //     return
            // }

            setForgotSent(true)
        } catch (err) {
            setForgotError(true)
            console.log('resetPassword: ', err)
        } finally {
            setForgotLoading(false)
        }
    }

    const handleCancelForgot = () => {
        setForgotError(null)
        setForgotSent(false)
        setForgot(false)
    }

    const handleKeyUp = (e) => {
        if (e.key === 'Enter') login()
    }

    const renderError = () => (
        <p>
            { error }
        </p>
    )

    const renderForgot = () => (
        <div className='login-method login-method--user-forgot'>
            {
                forgotSent ?
                <div className='login-method--user-forgot-sent'>
                    <p>{ t('login.forgot_sent') }</p>
                    <Button
                        className={'btn-cancel'}
                        label={t('login.back')}
                        onClick={handleCancelForgot}
                    />
                </div>
                :
                <div className='login-method--input'>
                    <Input
                        label={ t('login.username') }
                        onChange={(e) => setCredentials({ ...credentials, email: e.target.value })}
                        value={credentials.email}
                        type={'email'}
                    />
                    {
                        forgotError ?
                        <p>{ t('login.forgot_error') }</p>
                        :
                        <></>
                    }
                    <div className='login-actions'>
                        <Button
                            className={'btn-cancel'}
                            label={t('login.cancel')}
                            onClick={handleCancelForgot}
                        />
                        <Button
                            loading={forgotLoading}
                            label={t('login.reset_password')}
                            onClick={resetPassword}
                        />
                    </div>
                </div>
            }
        </div>
    )

    return (
        <div className='login'>
        {
            forgot ?
            renderForgot()
            :
            <div className='login-method login-method--user'>
                <div className='login-brands'>
                    <div className='login-brand login-brand--fishoil'></div>
                    <div className='login-brand login-brand--haarslev'></div>
                </div>
                <div className='login-method--input'>
                    <div className='user-input'>
                        <Input
                            label={ t('login.username') }
                            onChange={(e) => setCredentials({ ...credentials, email: e.target.value })}
                            value={credentials.email}
                            type={'email'}
                        />
                        <SelectInput
                            label={ t('login.warehouse_user') }
                            placeholder={ t('login.warehouse_user_placeholder') }
                            options={warehouseUsers}
                            value={warehouseUsers?.length ? warehouseUsers.find((user) => user.email === credentials.email) : null}
                            getOptionLabel={(option) => option.name}
                            getOptionValue={(option) => option.email}
                            onChange={(option) => setCredentials({ ...credentials, email: option.email })}
                        />
                    </div>
                    <Input
                        label={ t('login.password') }
                        type={'password'}
                        value={credentials.password}
                        onChange={(e) => setCredentials({ ...credentials, password: e.target.value })}
                        onKeyUp={handleKeyUp}
                    />
                    {
                    error ?
                        <div className='login-method--error'>
                            {
                                renderError()
                            }
                        </div>
                        :
                        <></>
                    }
                    <div className='login-actions'>
                        <Button
                            label={t('login.enter')}
                            onClick={login}
                        />
                    </div>
                    <a onClick={() => setForgot(true)}>{ t('login.forgot_password') }</a>
                </div>
            </div>
        }
        </div>
    )
}

export default LoginScreen